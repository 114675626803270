import React, { useContext } from "react";
import logo from "./logo.svg";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import { Home } from "./pages/Home";
import Login from "./auth/Login";
import Signup from "./auth/Signup";

import { PlayForm } from "./pages/PlayForm";
import { Profile } from "./pages/Profile";
import { EditBank } from "./pages/EditBank";
import { Password } from "./pages/Password";
import { Index } from "./pages/bookie/Index";

import { Withdraw } from "./pages/Withdraw";
import { Results } from "./pages/bookie/Results";
import { AdminIndex } from "./pages/admin/Index";
import { BookieList } from "./pages/admin/BookieList";
import { Package } from "./pages/admin/Package";
import { AddGames } from "./pages/admin/AddGames";
import { Result } from "./pages/admin/Result";
import { History } from "./pages/History";
import { AddUser } from "./pages/bookie/AddUser";
import { Payments } from "./pages/admin/Payments";
import { WithdrawList } from "./pages/bookie/WithdrawList";
import { UserHistory } from "./pages/bookie/UserHistory";
import { MyReferal } from "./pages/bookie/MyReferal";
import { Notification } from "./pages/bookie/Notification";


import { Card, CardContent, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { UserProvider } from "./pages/context/UserContext";

import UserContext from "./pages/context/UserContext";
import { SelectType } from "./pages/SelectType";
import { OtcType } from "./pages/OtcType";
import { DigitForm } from "./pages/DigitForm";
import { PlayGame } from "./pages/bookie/PlayGame";
import { AddRates } from "./pages/admin/AddRates";
import { SelectGame } from "./pages/bookie/SelectGame";
import { Report } from "./pages/bookie/Report";
import { AdminReport } from "./pages/admin/Report";
import { TokenList } from "./pages/bookie/TokenList";
import { TokenHistory } from "./pages/bookie/TokenHistory";
import { Dashboard } from "./pages/admin/Dashboard";
import { WinnerList } from "./pages/admin/WinnerList";
import { AdminHistory } from "./pages/admin/AdminHistory";
import { AdWithdrawList } from "./pages/admin/AdWithdraw";
import { BookieWithdrawList } from "./pages/admin/BookieWithdrawList";
import { AdminNotify } from "./pages/admin/AdminNotify";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },
  card: {
    textAlign: "center",
    flexDirection: "column",
  },

  gameHeader: {
    fontSize: "14",
    fontWeight: "bold",
    fontFamily: "Montserrat",
  },
  result: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
}));

function App() {
  const { user, bookie } = useContext(UserContext);

  const classes = useStyles();

  //if bookie status suspended
  if (bookie.status === "suspend") {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography
            className={classes.gameHeader}
            color="textSecondary"
            gutterBottom
          >
            Bookie account suspended
          </Typography>
          <Typography variant="h5" component="h2"></Typography>
          <Typography color="textSecondary" className={classes.result}>
            Contact your bookie {bookie.contact_no}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  //if account deleted
  if (user.status === "deleted") {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography
            className={classes.gameHeader}
            color="textSecondary"
            gutterBottom
          >
            Your account has been deleted by your bookie
          </Typography>
          <Typography variant="h5" component="h2"></Typography>
          <Typography color="textSecondary" className={classes.result}>
            Play with other bookie Contact : 8605906808
          </Typography>
          <Typography color="textSecondary" className={classes.result}>
            Complaint against bookie : 8605906808
          </Typography>
        </CardContent>
      </Card>
    );
  }

  //if account deleted
  if (user.status === "deactive") {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography
            className={classes.gameHeader}
            color="textSecondary"
            gutterBottom
          >
            Your account has been deactivated by bookie
          </Typography>
          <Typography variant="h5" component="h2"></Typography>

          <Typography color="textSecondary" className={classes.result}>
            Complaint against bookie : 8605906808
          </Typography>
        </CardContent>
      </Card>
    );
  }

  //err msg for bookie as user

  if (user.status === "suspend") {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography
            className={classes.gameHeader}
            color="textSecondary"
            gutterBottom
          >
            Your account has been suspended by admin
          </Typography>
          <Typography variant="h5" component="h2"></Typography>
          <Typography color="textSecondary" className={classes.result}>
            Please contact admin
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route path="/signup/:referal" component={Signup} />

          <Route path="/playform/:gid" component={PlayForm} />
          <Route exact path="/selectType/:gid" component={SelectType} />
          <Route path="/otcType/:gid/:type" component={OtcType} />
          <Route path="/digitForm/:gid/:type/:otc" component={DigitForm} />
          <Route path="/profile" component={Profile} />
          <Route path="/editbank" component={EditBank} />
          <Route path="/password" component={Password} />
          <Route path="/withdraw" component={Withdraw} />
          <Route path="/history" component={History} />


          <Route path="/bookie/index" component={Index} />
     
          <Route path="/bookie/results" component={Results} />
          <Route path="/bookie/adduser" component={AddUser} />
          <Route path="/bookie/withdraw" component={WithdrawList} />
          <Route path="/bookie/history/:uid" component={UserHistory} />
          <Route path="/bookie/myreferal" component={MyReferal} />
          <Route path="/bookie/notification" component={Notification} />
          <Route path="/bookie/playGame/:gid" component={PlayGame} />
          <Route path="/bookie/selectGame" component={SelectGame} />
          <Route path="/bookie/report" component={Report} />
          <Route path="/bookie/tokenList" component={TokenList} />
          <Route path="/bookie/tokenHistory/:uid" component={TokenHistory} />

          <Route exact path="/admin" component={Dashboard} />
          <Route exact path="/admin/userList" component={AdminIndex} />
          <Route path="/admin/bookieList" component={BookieList} />
          <Route path="/admin/package" component={Package} />
          <Route path="/admin/addgames" component={AddGames} />
          <Route path="/admin/result" component={Result} />
          <Route path="/admin/payments" component={Payments} />
          <Route path="/admin/addRates" component={AddRates} />
          <Route path="/admin/adminReport" component={AdminReport} />
          <Route path="/admin/winnerList" component={WinnerList} />
          <Route path="/admin/withdraw" component={AdWithdrawList} />
          <Route path="/admin/BookieWithdrawList" component={BookieWithdrawList} />
          <Route path="/admin/history/:uid" component={AdminHistory} />
          <Route path="/admin/notification" component={AdminNotify} />

        
          <Route path="/moderator/result" component={Result} />
          <Route path="/moderator/report" component={AdminReport} />
        
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default () => {
  return (
    <UserProvider>
      <App />
    </UserProvider>
  );
};
