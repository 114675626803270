import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import UserContext from "../context/UserContext";

const useStyles = makeStyles({
  tableContainer: {
    flexDirection: "column",
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
   fontFamily: "Josefin Sans",
  },
  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
  },
  tableFont: {
   fontFamily: "Josefin Sans",
    color: "#000",
    fontWeight: "bold",
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
});

export default function GameRatio() {
  const { rate } = useContext(UserContext);

  const classes = useStyles();
  // console.log(data);
  return (
    <>
      <Toolbar className={classes.subHead}>
        <Typography variant="h6" className={classes.title}>
          Game Rates
        </Typography>
      </Toolbar>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.tableFont}>
                Game
              </TableCell>
              <TableCell align="center" className={classes.tableFont}>
                Rate
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                align="center"
                component="th"
                scope="row"
                className={classes.tableFont}
              >
                Single
              </TableCell>
              <TableCell align="center" className={classes.tableFont}>
                1: {rate.single}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                component="th"
                scope="row"
                className={classes.tableFont}
              >
                Jodi
              </TableCell>
              <TableCell align="center" className={classes.tableFont}>
                1: {rate.jodi}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                component="th"
                scope="row"
                className={classes.tableFont}
              >
                Single Patti
              </TableCell>
              <TableCell align="center" className={classes.tableFont}>
                1: {rate.single_patti}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                component="th"
                scope="row"
                className={classes.tableFont}
              >
                Double Patti
              </TableCell>
              <TableCell align="center" className={classes.tableFont}>
                1: {rate.double_patti}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                component="th"
                scope="row"
                className={classes.tableFont}
              >
                Tripple Patti
              </TableCell>
              <TableCell align="center" className={classes.tableFont}>
                1:{rate.tripple_patti}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
