import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import MenuBar from "../wrapper/MenuBar";

import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Alert } from "@material-ui/lab";

import * as moment from "moment";
import { Redirect } from "react-router-dom";
import UserContext from "../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },

  title: {
    fontFamily: "Montserrat",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#f44336",
    fontWeight: "bold",
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  form: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
  },
  field: {
    // margin: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submit: {
    fontFamily: "Montserrat",
    backgroundColor: "#f44336",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  tableContainer: {
    flexDirection: "column",
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
  },
  tableFont: {
    fontFamily: "Montserrat",
    color: "#000",
    fontWeight: "bold",
  },
  paginate: {
    margin: theme.spacing(1),
  },
}));

export const Payments = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  const [payments, setPayments] = useState([]);
  const [bookies, setBookies] = useState([]);
  const [packages, setPackage] = useState([]);

  const [bookieId, setBookieId] = useState("");
  const [packId, setPackId] = useState("");
  const [deposite, setDeposite] = useState("");

  //refresh page
  const [refresh, setResfresh] = useState(false);

  //backdrop
  const [backDrop, setbackDrop] = useState(false);

  //snackbar
  const [snackBar, setSnackBar] = useState(false);

  //dialog state
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(async () => {
    const getPayments = await axios.get("admin/payments");

    setPayments(getPayments.data);
  }, [refresh]);

  //snackbar close function
  const handleClose = () => {
    setSnackBar(false);
  };

  //make paid function
  const makePaid = async (id, bookie_id, type) => {
    const res = await axios.put("admin/payments/" + id, {
      type,
      bookie_id,
    });
    setResfresh((v) => !v);
  };

  const openDialog = async () => {
    const resBookie = await axios.get("bookieList");
    const getPackage = await axios.get("admin/package");
    setBookies(resBookie.data);
    setPackage(getPackage.data);
    setDialogOpen(true);
  };

  const dialogClose = () => {
    setDialogOpen(false);
  };
  //add payments
  const addPayment = async (e) => {
    e.preventDefault();
    setDialogOpen(false);
    const response = await axios.post("/admin/payments", {
      bookieId,
      packId,
      deposite,
    });

    console.log(response);
    setResfresh((v) => !v);
    setSnackBar(true);
  };

  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "bookie") {
    return <Redirect to="/bookie/index" />;
  }
  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }
  return (
    <div className={classes.root}>
      <MenuBar />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Payments
          </Typography>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={openDialog}
          >
            add
          </Button>
        </Toolbar>
        <Divider />

        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Bookie username
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Package Name
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Deposite
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Amount
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Validity
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Paid date
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Next Payment date
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Status
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.user.user_name}
                    </TableCell>

                    <TableCell align="center" className={classes.tableFont}>
                      {item.package.name}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.deposite}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.package.amount}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.package.validity}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {moment(item.paid_date).format("ll")}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {moment(
                        moment(item.paid_date)
                          .add(item.package.validity, "days")
                          .calendar()
                      ).format("ll")}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.status}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      <ButtonGroup
                        size="small"
                        aria-label="small outlined button group"
                      >
                        {item.status === "paid" ? (
                          <Button
                            onClick={() =>
                              makePaid(item.id, item.user_id, "unpaid")
                            }
                          >
                            Unpaid
                          </Button>
                        ) : (
                          <Button
                            onClick={() =>
                              makePaid(item.id, item.user_id, "paid")
                            }
                          >
                            Paid
                          </Button>
                        )}
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Bottom />
        <BottomNav />

        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className={classes.fontStyle} id="alert-dialog-title">
            Add Package to Bookie
          </DialogTitle>
          <form
            className={classes.form}
            noValidate
            onSubmit={addPayment}
            autoComplete="off"
          >
            <DialogContent>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="bookie">Select Bookie</InputLabel>
                <Select
                  value={bookieId}
                  id="bookie"
                  onChange={(e) => setBookieId(e.target.value)}
                >
                  {bookies.map((item) => {
                    return (
                      <MenuItem value={item.id}>{item.user_name}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="bookie">Select Package</InputLabel>
                <Select
                  value={packId}
                  id="bookie"
                  onChange={(e) => setPackId(e.target.value)}
                >
                  {packages.map((item) => {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              <TextField
                className={classes.search}
                value={deposite}
                onChange={(e) => setDeposite(e.target.value)}
                label="Deposite"
                fullWidth
                type="number"
              />
            </DialogContent>

            <DialogActions>
              <Button color="primary" type="submit">
                Save
              </Button>
              <Button color="primary" onClick={dialogClose}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={snackBar}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Updated
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};
