import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Card,
  CardContent,
  Typography,
  Toolbar,
  Button,
} from "@material-ui/core";

import MenuBar from "../wrapper/MenuBar";

import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Link, Redirect } from "react-router-dom";
import UserContext from "../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },
  card: {
    textAlign: "center",
    flexDirection: "column",
  },

  gameHeader: {
    fontSize: "14",
    fontWeight: "bold",
   fontFamily: "Josefin Sans",
  },
  result: {
   fontFamily: "Josefin Sans",
    fontWeight: "bold",
  },

  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  btn:{
    backgroundColor:'#00b7fa'
  }
}));

export const SelectGame = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  const [results, setResults] = useState([]);

  useEffect(async () => {
    const results = await axios.get("admin/result");

    setResults(results.data);
    // console.log(results);
  }, []);

  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "admin") {
    return <Redirect to="/admin" />;
  }
  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }
  return (
    <div className={classes.root}>
      <MenuBar />

      <Toolbar className={classes.subHead}>
        <Typography variant="h6" className={classes.title}>
          Select Game
        </Typography>
      </Toolbar>
      <Divider />

      {results.map((item) => {
        return (
          <Card className={classes.card} key={item.id}>
            <CardContent>
              <Typography
                className={classes.gameHeader}
                color="textSecondary"
                gutterBottom
              >
                {item.game_name}
              </Typography>
              <Typography variant="h5" component="h2"></Typography>
              <Typography color="textSecondary" className={classes.result}>
                {item.open}-{item.jodi}-{item.close}
              </Typography>
              <Button
            variant="contained"
            className={classes.btn}
            color="secondary"
            size="small"
            component={Link}
            to={"/bookie/playGame/"+item.game_id }
          >
            Play
          </Button>
            </CardContent>
          </Card>
        );
      })}

      <Divider />
      <Bottom />
      <BottomNav />
    </div>
  );
};
