import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  Chip,
  DialogActions,
} from "@material-ui/core";

import MenuBar from "../wrapper/MenuBar";
import { Link, Redirect } from "react-router-dom";
import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Alert } from "@material-ui/lab";
import UserContext from "../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },

  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
  },
  subHead: {
    backgroundColor: "#fafafa",
  },

  tableContainer: {
    flexDirection: "column",
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
   fontFamily: "Josefin Sans",
  },
  tableFont: {
   fontFamily: "Josefin Sans",
    color: "#000",

    fontSize:'20px',
  },
  paginate: {
    margin: theme.spacing(1),
  },
  tableFontTypo:{
    color:'#f44336',
    fontWeight:'bold',
    textDecoration:'none',
  },
  tableFontTypoBla:{
    color:'#000',
    fontWeight:'bold',
  },
  statusActive:{
    backgroundColor:'#4caf50',
    color:'#fff',
    fontFamily: "Josefin Sans",
    marginBottom:'5px',
  },
  chipInfo:{
    backgroundColor:"#f57c00",
    color:'#fff',
    fontFamily: "Josefin Sans",
    marginBottom:'5px',
  },
  chipRed:{
    backgroundColor:'#f44336',
    color:'#fff',
    fontFamily: "Josefin Sans",
    marginBottom:'5px',
  }
}));

export const BookieWithdrawList = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  const [withdrawLists, setWithdrawList] = useState([]);

  //refresh page
  const [refresh, setResfresh] = useState(false);

  //backdrop
  const [backDrop, setbackDrop] = useState(false);

  //snackbar
  const [snackBar, setSnackBar] = useState(false);


  const [userName, setUserName] = useState("");
  const [id, setId] = useState("");

  //reference value
  const [reference, setReference] = useState("");



  useEffect(async () => {
    const getWithdraw = await axios.get("bookieWithdrawList");

    setWithdrawList(getWithdraw.data);
    console.log(getWithdraw.data);
  }, [refresh]);



  //snackbar close function
  const handleClose = () => {
    setSnackBar(false);

  };



  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "bookie") {
    return <Redirect to="/bookie/index" />;
  }

  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }
  return (
    <div className={classes.root}>
      <MenuBar data={user} />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Withdraw List
          </Typography>
        </Toolbar>
        <Divider />

        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  User
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Contact No
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Withdraw amount
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Balance
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Info
                </TableCell>

                <TableCell align="center" className={classes.tableFont}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {withdrawLists.map((item) => {
                return (
                  <TableRow>
                    <TableCell align="center" className={classes.tableFont}>
                      Username: <span className={classes.tableFontTypo}>{item.user_name}</span> <br/>
                      Name: <span className={classes.tableFontTypoBla}>{item.name}</span> <br/>
                    Passbook: <Link className={classes.tableFontTypo} to={"/admin/history/" + item.user_id}>{item.user_name}</Link> <br/>
                    Agent Id: <span className={classes.tableFontTypoBla}>{item.bookie_id}</span> <br/>

                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      <span className={classes.tableFontTypo}>{item.contact_no}</span> <br/>
                      {item.email}

                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.amount}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.points}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                   

                    Bank Name: <span className={classes.tableFontTypoBla}>{item.bank_name}</span> <br/>
                    Acc no: <span className={classes.tableFontTypoBla}>{item.acc_no}</span> <br/>
                    Ifsc: <span className={classes.tableFontTypoBla}>{item.ifsc}</span> <br/>
                    Phonepe/upi: <span className={classes.tableFontTypo}>{item.upi_no}</span> <br/>
                 
                
                    </TableCell>

                    <TableCell align="center" className={classes.tableFont}>
                      {item.wstatus === "Cancelled" ? (
                        <Chip className={classes.chipRed} label="Cancelled" />
                      ) : item.wstatus === "Approved" ? (
                        <Chip className={classes.statusActive} label="Approved" />
                     
                      ) : item.wstatus === "Requested" ? (
                        <Chip className={classes.chipInfo} label="Requested" />
                 
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Bottom />
        <BottomNav data={user} />
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={snackBar}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Password Updated
          </Alert>
        </Snackbar>



      </>
    </div>
  );
};
