import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";

import * as moment from "moment";

import UserContext from "../context/UserContext";

const useStyles = makeStyles({
  body: {
    fontSize: 14,
  },
  tableContainer: {
    flexDirection: "column",
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
   fontFamily: "Josefin Sans",
  },
  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
  },
  tableFont: {
   fontFamily: "Josefin Sans",
    color: "#000",
    fontWeight: "bold",
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
});

export default function GameTime() {
  const { gameTime } = useContext(UserContext);

  const classes = useStyles();
  var today = new Date();
  const curDay = new Date(today).toLocaleString("en-us", { weekday: "long" });

  // console.log(moment("14:00:00", "HH:mm:ss").format("hh:mm A"));

  const open = (time) => {
    const jsonTime = JSON.parse(time);
    return jsonTime.map((res, i) => {
      if (res.day === curDay) {
        return moment(res.openTime, "HH:mm:ss").format("hh:mm A");
      }
    });
  };
  const close = (time) => {
    const jsonTime = JSON.parse(time);
    return jsonTime.map((res, i) => {
      if (res.day === curDay) {
        return moment(res.closeTime, "HH:mm:ss").format("hh:mm A");
      }
    });
  };

  return (
    <>
      <Toolbar className={classes.subHead}>
        <Typography variant="h6" className={classes.title}>
          Result Timetable
        </Typography>
      </Toolbar>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.tableFont}>
                Name
              </TableCell>
              <TableCell align="center" className={classes.tableFont}>
                Open
              </TableCell>
              <TableCell align="center" className={classes.tableFont}>
                Close
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gameTime.map((res) => {
              return (
                <TableRow key={res.id}>
                  <TableCell align="center" className={classes.tableFont}>
                    {res.name}
                  </TableCell>
                  <TableCell align="center" className={classes.tableFont}>
                    {open(res.runningTime)}
                  </TableCell>
                  <TableCell align="center" className={classes.tableFont}>
                    {close(res.runningTime)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
