import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  fontStyle: {
   fontFamily: "Josefin Sans",
    color: "#000",
  },
}));

export default function CustomDialog({ data, close }) {
  const classes = useStyles();
  return (
    <>
      <DialogTitle className={classes.fontStyle} id="alert-dialog-title">
        {data.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.fontStyle}
        >
          {data.msg}
        </DialogContentText>
      </DialogContent>

      {data.type === "success" ? (
        <DialogActions>
          <Button component={Link} to="/" color="primary" autoFocus>
            Go To home
          </Button>
          <Button component={Link} to="/history" color="primary" autoFocus>
            Go To History
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={close} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      )}
    </>
  );
}
