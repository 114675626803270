import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useParams, Redirect, Link } from "react-router-dom";
import * as moment from "moment";

import me from './../img/me.jpeg';

import {
  Divider,
  Typography,
  Toolbar,

  Grid,

  Backdrop,
  CircularProgress,
  Box,
  Button,
  Paper,
  TextField,
  InputAdornment,
  InputLabel,
  Dialog,
} from "@material-ui/core";

import axios from "axios";
import MenuBar from "./wrapper/MenuBar";

import BottomNav from "./wrapper/BottomNav";

import UserContext from "./context/UserContext";
import CustomDialog from "./inc/CustomDialog";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
   fontFamily: "Josefin Sans",
  },

  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
  },
  gameHead:{
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#95d6ed",
    fontWeight: "bold",
    color:'#fff',
    marginLeft:'15px',
    marginRight:'15px'
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  gridClass: {
    textAlign: "center",
    flexDirection: "column",
  },
  addbtn: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
   fontFamily: "Josefin Sans",
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  fontStyle: {
   fontFamily: "Josefin Sans",
  },

  tableContainer: {
    flexDirection: "column",
    marginTop: 50,
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
   fontFamily: "Josefin Sans",
  },
  tableFont: {
   fontFamily: "Josefin Sans",
    color: "#000",
    fontWeight: "bold",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  bottom: {
    marginBottom: 50,
  },
  text: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop:'30px'
  },
  submit: {
   fontFamily: "Josefin Sans",
    backgroundColor: "#00b7fa",

  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  otime:{
paddingLeft:'10px'
  },
  ctime:{
textAlign:"end",
paddingRight:'10px'
  },
  box:{
      backgroundColor:'#000',
      height:'50px',
      marginTop:'20px',
      },
      paper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent:'center',
        justifyContent:'center',
        marginTop:'20px',
        '& > *': {
          margin: theme.spacing(1),
          width: theme.spacing(20),
          height: theme.spacing(16),
          display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
        },
      },
      green:{
          backgroundColor:'green',
          color:'#fff',
          textDecoration:'none'
      },
      red:{
          backgroundColor:'red',
          color:'#fff'
      },
      input:{
          color:'red',
          textAlign:'center'
      },
      digit:{
          marginTop:'10px',
      },
      field:{
          paddingRight:'10px',
          paddingLeft:'10px',
      },
      label:{
          textAlign:'center',
          marginBottom:'5px',
          marginTop:'10px',
          fontSize:'20px',
          fontWeight:'bold'
      }
}));

export const DigitForm = () => {
  const { user, refreshPage } = useContext(UserContext);

  const classes = useStyles();

  let { gid,type, otc } = useParams();


  const [bidOpenTime, setBidOpenTime] = useState();
  const [bidCloseTime, setBidCloseTime] = useState();


  const [gameTime, setGameTime] = useState([]); //game time response from axios
  const [holiday, setHoliday] = useState(false);
  const [bidTimeOver, setBidTimeOver] = useState(false);
  const [openDisable, setOpenDisable] = useState(false);

  const [playArray, setPlayArray] = useState([]);

  const [tAmt,setTamt] = useState(0);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogArr, setDialogArr] = useState([]);

  //backdrop
  const [backDrop, setbackDrop] = useState(false);

  var today = new Date();
  const curDay = new Date(today).toLocaleString("en-us", { weekday: "long" });
  const curTime = moment(today.toLocaleTimeString(), "HH:mm A").format("HH:mm");

  useEffect(async () => {
    const game = await axios.get("/admin/games/" + gid);
    const runTimeJson = JSON.parse(game.data.runningTime);
    setGameTime(game.data);


    console.log(type);
    game.data.status === "running" ? setHoliday(false) : setHoliday(true);
    // console.log(curTime);

    runTimeJson.map((res, i) => {
        if (res.day === curDay) {
          if (res.status === "open") {
            setBidOpenTime(res.bidOpenTime);
            setBidCloseTime(res.bidCloseTime);
            if (curTime >= "00:00" && curTime <= "05:00") {
              return setBidTimeOver(true);
            }
            // console.log(res.bidCloseTime);
            res.bidCloseTime <= curTime
              ? setBidTimeOver(true)
              : setBidTimeOver(false);
  
            res.bidOpenTime <= curTime
              ? setOpenDisable(true)
              : setOpenDisable(false);
          } else {
            setHoliday(true);
            // console.log("work");
          }
        }
      });

    // console.log(runTimeJson);
  }, []);



  if (user.role === "bookie") {
    return <Redirect to="/bookie/index" />;
  }
  if (user.role === "admin") {
    return <Redirect to="/admin" />;
  }

  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }

  var arr = [];
if(type === 'single'){
    arr = [0,1,2,3,4,5,6,7,8,9];
}
if(type === 'jodi'){
    arr = ['00','01','02','03','04','05','06','07','08','09',
    '10','11','12','13','14','15','16','17','18','19',
    '20','21','22','23','24','25','26','27','28','29',
    '30','31','32','33','34','35','36','37','38','39',
    '40','41','42','43','44','45','46','47','48','49',
    '50','51','52','53','54','55','56','57','58','59',
    '60','61','62','63','64','65','66','67','68','69',
    '70','71','72','73','74','75','76','77','78','79',
    '80','81','82','83','84','85','86','87','88','89',
    '90','91','92','93','94','95','96','97','98','99',
];
}

if(type === 'singlePatti'){
    arr = [
        128,137,146,236,245,290,380,470,489,560,579,678,
        129,138,147,156,237,246,345,390,480,570,589,679,
        120,139,148,157,238,247,256,346,490,580,670,689,
        130,149,158,167,239,248,257,347,356,590,680,789,
        140,159,168,230,249,258,267,348,357,456,690,780,
        123,150,169,178,240,259,268,349,358,367,457,790,
        124,160,179,250,269,278,340,359,368,458,467,890,
        125,134,170,189,260,279,350,369,378,459,468,567,
        126,135,180,234,270,289,360,379,450,469,478,568,
        127,136,145,190,235,280,389,370,460,479,569,578,
    ]
}

if(type === 'doublePatti'){
    arr = [
        100,119,155,227,335,344,399,588,669,
        110,200,228,255,336,499,660,688,778,
        166,229,300,337,355,445,599,779,788,
        112,220,266,338,400,446,455,699,770,
        113,122,177,339,366,447,500,799,889,
        114,277,330,448,466,556,600,880,899,
        115,133,188,223,377,449,557,566,700,
        116,224,233,288,440,477,558,800,990,
        117,144,199,225,388,559,577,667,900,
        118,226,244,299,334,488,550,677,668,
    ]
}

if(type === 'tripplePatti'){
    arr = [
        '000','111','222','333','444','555','666','777','888','999',
    ]
}


const amount = (digit,amount) =>{
    const findP = playArray.find((p) => p.digit === digit);

    if(findP){
        findP.amount = amount;
    }
    else{
        const array = {
            digit,
            amount
          };
          setPlayArray([...playArray, array]);
    }
    
const total = playArray.reduce((a,v) =>  a = parseInt(a) + parseInt(v.amount) , 0 );
setTamt(total);
console.log(total);
}

const sbmitGame = async() =>{
    setbackDrop(true);
    if (parseInt(user.points) < tAmt) {
      const errArr = {
        title: "Insufficient points",
        msg: "You donot have sufficient Points.",
        type: "error",
      };
      setDialogArr(errArr);
      setbackDrop(false);

      return setDialogOpen(true);
    }
    if ( tAmt < 10) {
        const errArr = {
          title: "Bid On the numbers",
          msg: "Please Bid amount on number.",
          type: "error",
        };
        setDialogArr(errArr);
        setbackDrop(false);
  
        return setDialogOpen(true);
      }

      const responce = await axios.post("play", {
        playArray,
        gameName: gameTime.name,
        gameId: gameTime.id,
        type,
        otc
      });
      console.log(responce);
      if (responce.status === 200) {
        const errArr = {
          title: responce.data[0],
          msg: responce.data[1],
          type: "error",
        };
        setDialogArr(errArr);
        setbackDrop(false);
        setDialogOpen(true);
      }
      if (responce.status === 202) {
        const errArr = {
          title: responce.data[0],
          msg: responce.data[1],
          type: "success",
        };
        refreshPage();
        setDialogArr(errArr);
        setbackDrop(false);
        setDialogOpen(true);
      }
}
const dialogClose = () => {
    setDialogOpen(false);
  };


  return (
    <div className={classes.root} overflow="hidden">
      <MenuBar data={user} />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            {holiday
              ? "Today Is holiday for " + gameTime.name
              : bidTimeOver
              ? "Bid Time Over for " + gameTime.name
              : gameTime.name} { type } {otc}
              
          </Typography>
          
        </Toolbar>
  
        <br/>

        <Typography variant="h6" className={classes.gameHead}>
          Enter Amount
          </Typography>
          <Grid container className={classes.digit}>

{
//    createElement(type)
        arr.map((res,i) =>{
            return <Grid item xs={3} sm={2} className={classes.field}>
            <InputLabel className={classes.label} >{res}</InputLabel>
            <TextField
            id="outlined-required"
            variant="outlined"
            type="number"
            onChange={
            (e) => amount(res,e.target.value)
            }
          />
            </Grid>;
        })
}


              </Grid>
              <Grid container className={classes.digit}>
              <Grid item xs={3} sm={2}>
                  </Grid>
                  <Grid item xs={3} sm={2}>
                  </Grid>
                  <Grid item xs={3} sm={2}>
                  </Grid>
              <Grid item xs={3} sm={2} className={classes.field}>
            <InputLabel className={classes.label} >Total</InputLabel>
            <TextField
            id="outlined-required"
            variant="outlined"
            type="number"
            value={tAmt}
          />
            </Grid>;
              </Grid>
         
       
        <Divider />

        <Button
          variant="contained"
          className={classes.submit}
          color="secondary"
          fullWidth={true}
          disabled={holiday ? true : bidTimeOver ? true : false}
          onClick={sbmitGame}
        >
          Submit Game
        </Button>
 

    
        <Toolbar className={classes.bottom}>
          <Typography variant="h6" className={classes.text}>
            Made With love
          </Typography>
        </Toolbar>
        <BottomNav data={user} />
  
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CustomDialog data={dialogArr} close={dialogClose} />
        </Dialog>

        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    </div>
  );
};
