import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";

import MenuBar from "./wrapper/MenuBar";

import BottomNav from "./wrapper/BottomNav";

import axios from "axios";
import Bottom from "./wrapper/Bottom";
import { Alert } from "@material-ui/lab";

import UserContext from "./context/UserContext";
import { Redirect } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },
  card: {
    textAlign: "center",
    flexDirection: "column",
  },
  playBtn: {
    justifyContent: "center",
    alignItems: "center",
  },
  gameHeader: {
    fontSize: "14",
    fontWeight: "bold",
   fontFamily: "Josefin Sans",
  },
  result: {
   fontFamily: "Josefin Sans",
    fontWeight: "bold",
  },
  btn: {
    backgroundColor: "#f44336",
  },
  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  form: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
  },
  field: {
    // margin: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submit: {
   fontFamily: "Josefin Sans",
    backgroundColor: "#00b7fa",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const EditBank = () => {
  const classes = useStyles();

  const { user } = useContext(UserContext);

  //user account details
  const [bank, setBank] = useState();
  const [accNo, setAccNo] = useState();
  const [ifsc, setIfsc] = useState();
  const [upi, setUpi] = useState();

  const [bankErr, setBankErr] = useState(false);
  const [accNoErr, setAccNoErr] = useState(false);
  const [ifscErr, setIfscEr] = useState(false);
  const [upiErr, setUpiErr] = useState(false);

  //backdrop
  const [backDrop, setbackDrop] = useState(false);

  //snackbar
  const [snackBar, setSnackBar] = useState(false);

  useEffect(() => {
    setBank(user.bank_name);
    setAccNo(user.acc_no);
    setIfsc(user.ifsc);
    setUpi(user.upi_no);
  }, [user]);

  const submitBank = async (e) => {
    setbackDrop(true);
    e.preventDefault();
    // console.log(accNo);
    if (bank === null) {
      setbackDrop(false);
      return setBankErr(true);
    }
    if (accNo === null) {
      setbackDrop(false);
      return setAccNoErr(true);
    }
    if (ifsc === null) {
      setbackDrop(false);
      return setIfscEr(true);
    }
    if (upi === null) {
      setbackDrop(false);
      return setUpiErr(true);
    }
    setBankErr(false);
    setAccNoErr(false);
    setIfscEr(false);
    setUpiErr(false);

    const response = await axios.put("user/" + user.id, {
      type: "bank",
      bank,
      accNo,
      ifsc,
      upi,
    });
    if (response.status === 202) {
      setbackDrop(false);
      setSnackBar(true);
    }
  };
  //snackbar close function
  const handleClose = () => {
    setSnackBar(false);
  };

  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }

  return (
    <div className={classes.root}>
      <MenuBar data={user} />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Add Bank Details
          </Typography>
        </Toolbar>
        <Divider />
        <form
          className={classes.form}
          noValidate
          onSubmit={submitBank}
          autoComplete="off"
        >
          <TextField
            fullWidth
            variant="outlined"
            label="Bank Name"
            className={classes.field}
            value={bank}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setBank(e.target.value)}
            error={bankErr ? true : false}
            helperText={bankErr ? "Write Bank Name" : ""}
          />
          <TextField
            fullWidth
            variant="outlined"
            multiline
            label="Account Number"
            className={classes.field}
            value={accNo}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setAccNo(e.target.value)}
            error={accNoErr ? true : false}
            helperText={accNoErr ? "Write Account Number" : ""}
          />
          <TextField
            fullWidth
            value={ifsc}
            variant="outlined"
            autoCapitalize
            label="IFSC code"
            className={classes.field}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setIfsc(e.target.value)}
            error={ifscErr ? true : false}
            helperText={ifscErr ? "Write IFSC Code" : ""}
          />
          <TextField
            fullWidth
            value={upi}
            variant="outlined"
            multiline
            label="UPI id/ Phonepe Number"
            className={classes.field}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setUpi(e.target.value)}
            error={upiErr ? true : false}
            helperText={upiErr ? "Write Upi Id Or Phone Number" : ""}
          />
          <Button
            fullWidth
            variant="contained"
            className={classes.submit}
            color="secondary"
            type="submit"
          >
            Submit
          </Button>
        </form>

        <Divider />
        <Bottom />
        <BottomNav data={user} />
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={snackBar}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Bank Details Updated
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};
