import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";

import MenuBar from "../wrapper/MenuBar";

import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Alert } from "@material-ui/lab";
import { Redirect } from "react-router-dom";

import UserContext from "../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },

  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  form: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
  },
  field: {
    // margin: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submit: {
   fontFamily: "Josefin Sans",
    backgroundColor: "#00b7fa",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const AddUser = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  //input field state
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [password, setPassword] = useState("");

  //input field err
  const [nameErr, setNameErr] = useState(false);
  const [usernameErr, setUsernameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [contactErr, setContactErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);

  //backdrop
  const [backDrop, setbackDrop] = useState(false);

  //snackbar
  const [snackBar, setSnackBar] = useState(false);

  const [snackMsg, setSnackMsg] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("");

  const submitUser = async (e) => {
    e.preventDefault();
    // setbackDrop(true);
    name === "" ? setNameErr(true) : setNameErr(false);
    username === "" ? setUsernameErr(true) : setUsernameErr(false);
    email === "" ? setEmailErr(true) : setEmailErr(false);
    contact === "" ? setContactErr(true) : setContactErr(false);
    password === "" ? setPasswordErr(true) : setPasswordErr(false);

    setbackDrop(true);
    try {
      const res = await axios.post("bookieUser", {
        name,
        username,
        email,
        contact,
        password,
      });
      if (res.data === "exceed") {
        setSnackMsg("Bookie user limit exceeded.. ");
        setSnackSeverity("error");
        setbackDrop(false);
        setSnackBar(true);
        return;
      }
      setSnackMsg("User created...");
      setSnackSeverity("success");
      setbackDrop(false);
      setSnackBar(true);
    } catch (e) {
      setSnackMsg("Username / Email / Contact number already exist");
      setSnackSeverity("error");
      setbackDrop(false);
      setSnackBar(true);
      // console.log(e);
    }
  };
  //snackbar close function
  const handleClose = () => {
    setSnackBar(false);
  };

  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "admin") {
    return <Redirect to="/admin" />;
  }
  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }
  return (
    <div className={classes.root}>
      <MenuBar data={user} />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Add New User
          </Typography>
        </Toolbar>
        <Divider />
        <form
          className={classes.form}
          noValidate
          onSubmit={submitUser}
          autoComplete="off"
        >
          <TextField
            fullWidth
            variant="outlined"
            label="Name"
            className={classes.field}
            value={name}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setName(e.target.value)}
            error={nameErr ? true : false}
            helperText={nameErr ? "Please write name" : ""}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Username"
            className={classes.field}
            value={username}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setUsername(e.target.value)}
            error={usernameErr ? true : false}
            helperText={usernameErr ? "Please write username" : ""}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Email Address"
            className={classes.field}
            value={email}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setEmail(e.target.value)}
            error={emailErr ? true : false}
            helperText={emailErr ? "Plaese write email address" : ""}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Contact Number"
            type="number"
            className={classes.field}
            value={contact}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setContact(e.target.value)}
            error={contactErr ? true : false}
            helperText={contactErr ? "Please write contact number" : ""}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Password"
            className={classes.field}
            value={password}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setPassword(e.target.value)}
            error={passwordErr ? true : false}
            helperText={passwordErr ? "Please write password" : ""}
          />

          <Button
            fullWidth
            variant="contained"
            className={classes.submit}
            color="secondary"
            type="submit"
          >
            Submit
          </Button>
        </form>

        <Divider />
        <Bottom />
        <BottomNav data={user} />
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={snackBar}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={snackSeverity}>
            {snackMsg}
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};
