import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Card,
  CardContent,
  Typography,
  Toolbar,
  CardActions,
  Button,
  Paper,
  Grid,
} from "@material-ui/core";

import MenuBar from "../wrapper/MenuBar";

import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Redirect } from "react-router-dom";
import UserContext from "../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
    fontFamily: "Josefin Sans",
  },

  title: {
    fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
    fontSize: '25px'
  },

  paper: {
    margin: '10px',
  },
  paperText: {
    fontFamily: "Josefin Sans",
    textAlign: "center",
    fontSize: '20px',

    fontWeight: "bold",
  }
}));

export const Dashboard = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();


  const [users, setUsers] = useState('');
  const [agents, setAgents] = useState('');
  const [deposit, setDeposit] = useState('');
  const [wonAmt, setWonAmt] = useState('');
  const [withdraw, setWithdraw] = useState('');
  const [pending, setPending] = useState('');

  useEffect(async () => {
    const results = await axios.get("dashboardValues");

    setUsers(results.data[0]['totalUsers']);
    setAgents(results.data[1]['totalAgents']);
    setDeposit(results.data[2]['totalDeposit']);
    setWonAmt(results.data[3]['wonAmt']);
    setWithdraw(results.data[4]['totalWithdraw']);
    setPending(results.data[5]['pendingWithdraw']);
    // console.log(results.data[0]['totalUsers']);
  }, []);

  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "bookie") {
    return <Redirect to="/bookie/index" />;
  }
  if (user.role === 'moderator') {
    return <Redirect to="/moderator/report" />
  }
  return (
    <div className={classes.root}>
      <MenuBar />

      <Toolbar className={classes.subHead}>
        <Typography variant="h6" className={classes.title}>
          Dashboard
        </Typography>
      </Toolbar>
      <Divider />
      <Grid container >
        <Grid item xs={12} sm={4}>
          <Card className={classes.paper}>
            <CardContent>
              <Typography className={classes.paperText} color="textSecondary" gutterBottom>
                Users
        </Typography>

              <Typography className={classes.paperText} color="textSecondary">
                {users}
              </Typography>

            </CardContent>

          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>

          <Card className={classes.paper}>
            <CardContent>
              <Typography className={classes.paperText} color="textSecondary" gutterBottom>
                Agents
        </Typography>

              <Typography className={classes.paperText} color="textSecondary">
                {agents}
              </Typography>

            </CardContent>

          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>

          <Card className={classes.paper}>
            <CardContent>
              <Typography className={classes.paperText} color="textSecondary" gutterBottom>
                Total Deposit
        </Typography>

              <Typography className={classes.paperText} color="textSecondary">
                {deposit}
              </Typography>

            </CardContent>

          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>

          <Card className={classes.paper}>
            <CardContent>
              <Typography className={classes.paperText} color="textSecondary" gutterBottom>
                Total Won Amount
        </Typography>

              <Typography className={classes.paperText} color="textSecondary">
                {wonAmt}
              </Typography>

            </CardContent>

          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>

          <Card className={classes.paper}>
            <CardContent>
              <Typography className={classes.paperText} color="textSecondary" gutterBottom>
                Total Withdraw
        </Typography>

              <Typography className={classes.paperText} color="textSecondary">
                {withdraw}
              </Typography>

            </CardContent>

          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>

          <Card className={classes.paper}>
            <CardContent>
              <Typography className={classes.paperText} color="textSecondary" gutterBottom>
                Total Pending Withdraw
        </Typography>

              <Typography className={classes.paperText} color="textSecondary">
                {pending}
              </Typography>

            </CardContent>

          </Card>
        </Grid>
      </Grid>

      <Divider />
      <Bottom />
      <BottomNav />
    </div>
  );
};
