import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  ButtonGroup,
} from "@material-ui/core";

import MenuBar from "../wrapper/MenuBar";

import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Alert } from "@material-ui/lab";
import { Redirect } from "react-router-dom";
import UserContext from "../context/UserContext";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },

  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
    fontSize:'25px'
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  form: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
  },
  field: {
    // margin: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submit: {
   fontFamily: "Josefin Sans",
    backgroundColor: "#00b7fa",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  tableContainer: {
    flexDirection: "column",
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Josefin Sans",
  },
  tableFont: {
    fontFamily: "Josefin Sans",
    color: "#000",
    fontWeight: "bold",
  },
}));

export const AddRates = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  //values
  const [single, setSingle] = useState("");
  const [jodi, setJodi] = useState("");
  const [singlePatti, setSinglePatti] = useState("");
  const [doublePatti, setDoublePatti] = useState("");
  const [tripplePatti, setTripplePatti] = useState("");

  //backdrop
  const [backDrop, setbackDrop] = useState(false);

  //snackbar
  const [snackBar, setSnackBar] = useState(false);

  //rates
  const [rates,setRates] = useState([]);

   //rate submit type
   const [type, setType] = useState("new");
     //rate id for edit
  const [rateId, setRateId] = useState(0);

  const [refresh,setRefresh] = useState('');

  useEffect(async () => {
    const bookieRate = await axios.get("bookieRate");

    setRates(bookieRate.data);

   
    // console.log(bookieRate);
  }, [refresh]);

  const submitRate = async (e) => {
    setbackDrop(true);
    e.preventDefault();
    const res = await axios.post("bookieRate", {
      single,
      jodi,
      singlePatti,
      doublePatti,
      tripplePatti,
      rateId,
      type
    });

    if (res.status === 200) {
      setbackDrop(false);
      setSnackBar(true);
      setRefresh(v=>!v);
    }
    if (res.status === 201) {
      setbackDrop(false);
      setSnackBar(true);
      setRefresh(v=>!v);
    }
  };

    //Edit Package
    const editGame = (id, single,jodi,single_patti,double_patti,tripple_patti) => {
        setRateId(id);
        setSingle(single);
        setJodi(jodi);
        setSinglePatti(single_patti);
        setDoublePatti(double_patti);
        setTripplePatti(tripple_patti);
        setType("edit");
      };
        //delete package
  const deleteRate = (id) => {

  };

  //snackbar close function
  const handleClose = () => {
    setSnackBar(false);
  };
  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "bookie") {
    return <Redirect to="/bookie/index" />;
  }

  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }

  return (
    <div className={classes.root}>
      <MenuBar />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Set Game Rates
          </Typography>
        </Toolbar>
        <Divider />
        <form
          className={classes.form}
          noValidate
          onSubmit={submitRate}
          autoComplete="off"
        >
          <TextField
            fullWidth
            variant="outlined"
            label="Single"
            type="number"
            className={classes.field}
            InputLabelProps={{
              shrink: true,
            }}
            value={single}
            onChange={(e) => setSingle(e.target.value)}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Jodi"
            type="number"
            className={classes.field}
            InputLabelProps={{
              shrink: true,
            }}
            value={jodi}
            onChange={(e) => setJodi(e.target.value)}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Single Patti"
            type="number"
            className={classes.field}
            InputLabelProps={{
              shrink: true,
            }}
            value={singlePatti}
            onChange={(e) => setSinglePatti(e.target.value)}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Double Patti"
            type="number"
            className={classes.field}
            InputLabelProps={{
              shrink: true,
            }}
            value={doublePatti}
            onChange={(e) => setDoublePatti(e.target.value)}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Triple Patti"
            type="number"
            className={classes.field}
            InputLabelProps={{
              shrink: true,
            }}
            value={tripplePatti}
            onChange={(e) => setTripplePatti(e.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            className={classes.submit}
            color="secondary"
            type="submit"
          >
            Submit
          </Button>
        </form>

        <Divider />
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Id
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Single
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Jodi
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Single Patti
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Double Patti
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Tripple Patti
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rates.map((item) => {
                return (
                  <TableRow>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.id}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.single}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.jodi}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.single_patti}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.double_patti}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.tripple_patti}
                    </TableCell>

                    <TableCell align="center" className={classes.tableFont}>
                      <ButtonGroup
                        size="small"
                        aria-label="small outlined button group"
                      >
                        <Button
                          onClick={() =>
                            editGame(
                              item.id,
                              item.single,
                              item.jodi,
                              item.single_patti,
                              item.double_patti,
                              item.tripple_patti
                            )
                          }
                        >
                          <EditIcon fontSize="small" />
                        </Button>
                        <Button onClick={() => deleteRate(item.id)}>
                          <DeleteIcon fontSize="small" />
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Bottom />
        <BottomNav />
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={snackBar}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Rates Updated
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};
