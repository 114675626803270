import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MenuBar from "../wrapper/MenuBar";

import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Alert } from "@material-ui/lab";
import { Redirect } from "react-router-dom";
import UserContext from "../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },

  title: {
    fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Josefin Sans",
  },
  tableFont: {
    fontFamily: "Josefin Sans",
    color: "#000",
    fontWeight: "bold",
  },
  subHead: {
    backgroundColor: "#fafafa",
  },

  submit: {
    fontFamily: "Josefin Sans",
    backgroundColor: "#00b7fa",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
}));

export const AddGames = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  const [games, setGames] = useState([]);

  //game id for edit
  const [gameId, setGameId] = useState(0);

  //game submit type
  const [type, setType] = useState("new");

  //input values
  const [name, setName] = useState("");
  const [time, setTime] = useState("");
  const [status, setStatus] = useState("running");

  //validation err
  const [nameErr, setNameErr] = useState(false);
  const [timeErr, setTimeErr] = useState(false);

  //refresh page
  const [refresh, setResfresh] = useState(false);

  //backdrop
  const [backDrop, setbackDrop] = useState(false);

  //snackbar
  const [snackBar, setSnackBar] = useState(false);

  useEffect(async () => {
    const getGames = await axios.get("admin/games");

    setGames(getGames.data);
  }, [refresh]);

  //submit form
  const submitGame = async (e) => {
    e.preventDefault();
    if (name === "") {
      return setNameErr(true);
    }
    if (time === "") {
      return setTimeErr(true);
    }
    setNameErr(false);
    setTimeErr(false);
    const response = await axios.post("/admin/games", {
      name,
      time,
      status,
      type,
      gameId,
    });
    // console.log(response);
    setResfresh((v) => !v);
  };

  //Edit Package
  const editGame = (id, name, time, status) => {
    setGameId(id);
    setName(name);
    setTime(time);
    setStatus(status);
    setType("edit");
  };

  //delete package
  const deletePackage = (id) => {};

  //snackbar close function
  const handleClose = () => {
    setSnackBar(false);
  };

  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "bookie") {
    return <Redirect to="/bookie/index" />;
  }

  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }
  
  return (
    <div className={classes.root}>
      <MenuBar />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Add Game
          </Typography>
        </Toolbar>
        <Divider />
        <form onSubmit={submitGame}>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.tableFont}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      error={nameErr ? true : false}
                      helperText={nameErr ? "Please write name.." : ""}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="center" className={classes.tableFont}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Running Time"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      multiline
                      rows={15}
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      error={timeErr ? true : false}
                      helperText={timeErr ? "Please write running time" : ""}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className={classes.tableFont}>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="status">Select Bookie</InputLabel>
                      <Select
                        id="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <MenuItem value="running">Running</MenuItem>
                        <MenuItem value="closed">Closed</MenuItem>
                      </Select>
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            fullWidth
            variant="contained"
            className={classes.submit}
            color="secondary"
            type="submit"
          >
            Submit
          </Button>
        </form>

        <Divider />

        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Name
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Status
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {games.map((item) => {
                return (
                  <TableRow>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.name}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.status}
                    </TableCell>

                    <TableCell align="center" className={classes.tableFont}>
                      <ButtonGroup
                        size="small"
                        aria-label="small outlined button group"
                      >
                        <Button
                          onClick={() =>
                            editGame(
                              item.id,
                              item.name,
                              item.runningTime,
                              item.status
                            )
                          }
                        >
                          <EditIcon fontSize="small" />
                        </Button>
                        <Button onClick={() => deletePackage(item.id)}>
                          <DeleteIcon fontSize="small" />
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Bottom />
        <BottomNav />
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={snackBar}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Password Updated
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};
