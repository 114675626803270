import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Card,
  CardContent,
  Typography,
  Toolbar,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Link,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EditIcon from "@material-ui/icons/Edit";

import MenuBar from "./wrapper/MenuBar";

import BottomNav from "./wrapper/BottomNav";

import Bottom from "./wrapper/Bottom";

import UserContext from "./context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },
  card: {
    textAlign: "center",
    flexDirection: "column",
  },

  icon: {
    fontSize: 120,
  },
  name: {
   fontFamily: "Josefin Sans",
    fontWeight: "bold",
    color: "#00b7fa",
  },
  tableContainer: {
    flexDirection: "column",
  },
  tableFont: {
   fontFamily: "Josefin Sans",
    color: "#000",
    fontWeight: "bold",
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
   fontFamily: "Josefin Sans",
  },
  title: {
   fontFamily: "Josefin Sans",

    color: "#00b7fa",
    fontWeight: "bold",
  },
  btn: {
    marginLeft: 10,
    color: "#4791db",
  },
}));

export const Profile = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MenuBar data={user} />
      <>
        <Card className={classes.card}>
          <CardContent>
            <AccountCircleIcon className={classes.icon} />
            <Typography color="textSecondary" className={classes.name}>
              {user.name}
            </Typography>
          </CardContent>
        </Card>
        <Divider />
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableBody>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Name
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  {user.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Username
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  {user.user_name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Email
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  {user.email}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Contact No
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  {user.contact_no}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Bank Details
          </Typography>
          <Link href="/editbank" variant="body2">
            <EditIcon className={classes.btn} />
          </Link>
        </Toolbar>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableBody>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Bank Name
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  {user.bank_name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Account No
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  {user.acc_no}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  IFSC code
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  {user.ifsc}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  UPI ID
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  {user.upi}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Bottom />
        <BottomNav data={user} />
      </>
    </div>
  );
};
