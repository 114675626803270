import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  ButtonGroup,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
  Grid,
  InputLabel,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";

import MenuBar from "../wrapper/MenuBar";

import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Alert, Autocomplete, Pagination } from "@material-ui/lab";
import { Link, Redirect } from "react-router-dom";

import UserContext from "../context/UserContext";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";

import moment from "moment-timezone";
import { TokenToExcel } from "./TokenToExcel";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },

  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  tableContainer: {
    flexDirection: "column",
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
   fontFamily: "Josefin Sans",
  },
  tableFont: {
   fontFamily: "Josefin Sans",
    color: "#000",
    fontWeight: "bold",
  },
  paginate: {
    margin: theme.spacing(1),
  },
  search: {
    backgroundColor: "#fff",
   fontFamily: "Josefin Sans",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  field:{
    paddingRight:'10px',
    paddingLeft:'10px',
    marginBottom:'10px',
          marginTop:'10px',
          
},
formControl: {
    margin: theme.spacing(1),
    minWidth: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  grid:{
    marginTop:'10px',
    textAlign:'center'
  },
  text: {
    fontFamily: "Josefin Sans",
     textAlign: "center",
     marginLeft: "auto",
     marginRight: "auto",
   },
}));

export const TokenList = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  const [users, setUsers] = useState([]);

  const [pagination, setPagination] = useState([]);

  const [key, setKey] = useState("");

  const [page, setPage] = useState(1);

  const [userName, setUserName] = useState();


  //backdrop
  const [backDrop, setBackDrop] = useState(false);

  //refresh page
  const [refreshPage, setRefresh] = useState(false);


  const [fromDate,setFromDate] = useState(new Date());
  const [toDate,setToDate] = useState(new Date());

  const [gameName,setGameName] = useState('');
  const [gameNameErr,setGameNameErr] = useState(false);

  const [games,setGames] = useState([]);

  const [data,setData] =useState([]);

  const [report,setReport] = useState([]);

  const [tokens,setToken] = useState([]);


  moment.tz.setDefault('Asia/Kolkata');

  useEffect(async () => {
    const getUsers = await axios.post("searchToken",{
      key,
      type:'search'
    });

    setUsers(getUsers.data);
    console.log(getUsers);

  }, [key]);


  useEffect(async () => {
    const getGames = await axios.get("admin/games");

    setGames(getGames.data);
  }, []);



  const submit = async() =>{
    if(gameName === ''){
      return setGameNameErr(true);
    }
    setGameNameErr(false);

    const getUsers = await axios.post("searchToken", {
      gameName,
      userName,
      fromDate:moment(fromDate).tz("Asia/Kolkata").format("YYYY-MM-DD"),
      toDate:moment(toDate).tz("Asia/Kolkata").format("YYYY-MM-DD"),
    });
    setReport(getUsers.data[0]);
    setToken(getUsers.data[1]);
    setData(getUsers.data[0]);

    console.log(getUsers.data[0]);
  }

  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "admin") {
    return <Redirect to="/admin" />;
  }
  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }

  return (
    <div className={classes.root}>
      <MenuBar data={user} />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Token Report
          </Typography>
        </Toolbar>
        <Divider />

        <Grid container className={classes.digit}>
              <Grid item xs={6} sm={3} className={classes.field}>
              <InputLabel className={classes.label} >From</InputLabel>
        
          <DateTimePicker
        onChange={setFromDate} 
        value={fromDate}
        format="dd-MM-y"
      />
                  </Grid>
                  <Grid item xs={6} sm={3} className={classes.field}>
                  <InputLabel className={classes.label} >To</InputLabel>
               
          <DateTimePicker
        onChange={setToDate} 
        value={toDate}
        format="dd-MM-y"
      />
                  </Grid>
                  <Grid item xs={6} sm={3} className={classes.field}>
                  <FormControl className={classes.formControl} error={gameNameErr?true:false}>
                    <InputLabel htmlFor="select" shrink>
                      Select Game
                    </InputLabel>
                    <Select
                      id="select"
                      onChange={(e) => setGameName(e.target.value)}
                      value={gameName}
                      required
                    >
                      {games.map((item) => {
                        return (
                          <MenuItem value={item.name} key={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {
                        gameNameErr?'Please select gamename':''
                      }
                    </FormHelperText>
                  </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3} className={classes.field}>
              <InputLabel className={classes.label} >UserName</InputLabel>



            <Autocomplete
              id="controllable-states-demo"
              // options={userList.map((option) => option.name)}
              options={users}
              getOptionLabel={(option) => option.name}
              renderOption={(option)=>option.name}
              onChange={(e, fVal) => {
                setUserName(fVal);
              }}
              value={userName}
              onInputChange={(e, val) => {
                setKey(val);
              }}
              inputValue={key}
              renderInput={(params) => (
                <TextField
                  {...params}
                 
                  variant="outlined"
                />
              )}
            />
                  </Grid>
                  </Grid>
        <Button
          variant="contained"
          className={classes.submit}
          color="secondary"
          fullWidth={true}   
          onClick={submit}      
        >
          Get Report
        </Button>

      <Divider />

<br/>
<Button variant="contained" color="primary"  onClick={() => window.print()}>PRINT</Button>

<TokenToExcel apiData={data} tokens={tokens} filename={gameName+fromDate+toDate} />


      
<Grid container className={classes.grid}>
  <Grid item xs={4}>From: {moment(fromDate).tz("Asia/Kolkata").format("YYYY-MM-DD")}</Grid>
  <Grid item xs={4}>{gameName}</Grid>
  <Grid item xs={4}>To: {moment(toDate).tz("Asia/Kolkata").format("YYYY-MM-DD")}</Grid>
</Grid>
<Grid container>
{
  tokens === '' ? '': tokens.map((item) =>{

return  <Grid item xs={12} sm={6}>
      <Typography variant="h6" className={classes.title}>
          {item.token}
        </Typography>
      <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Number
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Played Amount
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Won Amount
                </TableCell>
             
              </TableRow>
            </TableHead>
            <TableBody>
        
                    

                {
                        report[item.token].map((item) =>{
                         return <TableRow>
                          <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        className={classes.tableFont}
                      >
                     {item.played_no}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                      {item.points}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                        {item.wonAmt}
                      </TableCell>
                     
                    </TableRow>
                        })
                      }
                      


              <TableRow>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  className={classes.tableFont}
                > Total</TableCell>
                <TableCell align="center" className={classes.tableFont}>
                 {
                  report[item.token].reduce((a,v) =>  a = parseInt(a) + parseInt(v.points) , 0 )
                }
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                {
                  report[item.token].reduce((a,v) =>  a = parseInt(a) + parseInt(v.wonAmt) , 0 )
                 }
                </TableCell>
            
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
    {/* single open end */}
    </Grid>

})
}

</Grid>


        <Divider />
        <Bottom />
        <BottomNav data={user} />
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>

 
      </>
    </div>
  );
};
