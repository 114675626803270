import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MenuBar from "../wrapper/MenuBar";

import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Alert } from "@material-ui/lab";
import { Redirect } from "react-router-dom";
import UserContext from "../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },

  title: {
    fontFamily: "Montserrat",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#f44336",
    fontWeight: "bold",
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  form: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
  },
  field: {
    // margin: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submit: {
    fontFamily: "Montserrat",
    backgroundColor: "#f44336",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  tableContainer: {
    flexDirection: "column",
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
  },
  tableFont: {
    fontFamily: "Montserrat",
    color: "#000",
    fontWeight: "bold",
  },
}));

export const Package = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  const [packages, setPackages] = useState([]);

  //package id for edit
  const [packId, setPackId] = useState(0);

  //type for edit
  const [type, setType] = useState("new");

  // form state
  const [name, setName] = useState("");
  const [descri, setDescri] = useState("");
  const [no_users, setNo_users] = useState(0);
  const [amt, setAmt] = useState(0);
  const [validity, setValidity] = useState(0);
  const [status, setStatus] = useState("active");

  //form err
  const [nameErr, setNameErr] = useState(false);
  const [descriErr, setDescriErr] = useState(false);
  const [no_usersErr, setNo_usersErr] = useState(false);
  const [amtErr, setAmtErr] = useState(false);
  const [validityErr, setValidityErr] = useState(false);

  //refresh page
  const [refresh, setResfresh] = useState(false);

  //backdrop
  const [backDrop, setbackDrop] = useState(false);

  //snackbar
  const [snackBar, setSnackBar] = useState(false);

  useEffect(async () => {
    const getPackage = await axios.get("admin/package");

    setPackages(getPackage.data);
    // console.log(getPackage);
  }, [refresh]);

  //submit form
  const submitPackage = async (e) => {
    e.preventDefault();
    if (name === "") {
      return setNameErr(true);
    }
    if (descri === "") {
      return setDescriErr(true);
    }
    if (no_users === 0) {
      return setNo_usersErr(true);
    }
    if (amt === 0) {
      return setAmtErr(true);
    }
    if (validity === 0) {
      return setValidityErr(true);
    }
    setNameErr(false);
    setDescriErr(false);
    setNo_usersErr(false);
    setAmtErr(false);
    setValidityErr(false);

    const response = await axios.post("/admin/package", {
      name,
      descri,
      no_users,
      amt,
      validity,
      packId,
      type,
      status,
    });
    // console.log(response);
    setResfresh((v) => !v);
  };

  //Edit Package
  const editPackage = (
    id,
    name,
    description,
    no_of_users,
    amount,
    validity,
    status
  ) => {
    setName(name);
    setDescri(description);
    setNo_users(no_of_users);
    setAmt(amount);
    setValidity(validity);
    setStatus(status);
    setPackId(id);
    setType("edit");
  };

  //delete package
  const deletePackage = (id) => {};

  //snackbar close function
  const handleClose = () => {
    setSnackBar(false);
  };

  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "bookie") {
    return <Redirect to="/bookie/index" />;
  }

  return (
    <div className={classes.root}>
      <MenuBar />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Set Package
          </Typography>
        </Toolbar>
        <Divider />
        <form
          className={classes.form}
          noValidate
          onSubmit={submitPackage}
          autoComplete="off"
        >
          <TextField
            fullWidth
            variant="outlined"
            label="Name"
            className={classes.field}
            InputLabelProps={{
              shrink: true,
            }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={nameErr ? true : false}
            helperText={nameErr ? "Please write name.." : ""}
          />
          <TextField
            fullWidth
            variant="outlined"
            multiline
            label="Description"
            className={classes.field}
            InputLabelProps={{
              shrink: true,
            }}
            value={descri}
            onChange={(e) => setDescri(e.target.value)}
            error={descriErr ? true : false}
            helperText={descriErr ? "Please write description..." : ""}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="No Of users"
            className={classes.field}
            InputLabelProps={{
              shrink: true,
            }}
            value={no_users}
            type="number"
            onChange={(e) => setNo_users(e.target.value)}
            error={no_usersErr ? true : false}
            helperText={no_usersErr ? "Please write no of users" : ""}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Price"
            className={classes.field}
            InputLabelProps={{
              shrink: true,
            }}
            value={amt}
            type="number"
            onChange={(e) => setAmt(e.target.value)}
            error={amtErr ? true : false}
            helperText={amtErr ? "Please add price.." : ""}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Validity"
            className={classes.field}
            InputLabelProps={{
              shrink: true,
            }}
            value={validity}
            type="number"
            onChange={(e) => setValidity(e.target.value)}
            error={validityErr ? true : false}
            helperText={validityErr ? "Please add validity" : ""}
          />
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="bookie">Select Bookie</InputLabel>
            <Select
              value={status}
              id="bookie"
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="deactive">Deactive</MenuItem>
            </Select>
            <FormHelperText></FormHelperText>
          </FormControl>

          <Button
            fullWidth
            variant="contained"
            className={classes.submit}
            color="secondary"
            type="submit"
          >
            Submit
          </Button>
        </form>

        <Divider />

        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Name
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Description
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  No of Users
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Amount
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Validity
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Status
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {packages.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.name}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.description}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.no_of_users}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.amount}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.validity}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.status}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      <ButtonGroup
                        size="small"
                        aria-label="small outlined button group"
                      >
                        <Button
                          onClick={() =>
                            editPackage(
                              item.id,
                              item.name,
                              item.description,
                              item.no_of_users,
                              item.amount,
                              item.validity,
                              item.status
                            )
                          }
                        >
                          <EditIcon fontSize="small" />
                        </Button>
                        <Button onClick={() => deletePackage(item.id)}>
                          <DeleteIcon fontSize="small" />
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Bottom />
        <BottomNav />
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={snackBar}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Password Updated
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};
