import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '@material-ui/core';

export const TokenToExcel = ({apiData,filename,tokens}) => {
    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (apiData, fileName,tokens) => {
    
        const title = [
            [fileName,'','']
        ]
        const emptyArr = [
            ['','','','']
        ]
        const ws = XLSX.utils.json_to_sheet(title,{origin:'B3',skipHeader: true});
        XLSX.utils.sheet_add_json(ws,emptyArr,{origin:-1,skipHeader:true});
        XLSX.utils.sheet_add_json(ws,emptyArr,{origin:-1,skipHeader:true});
        // XLSX.utils.sheet_add_json(ws,Header1,{origin:'B3',skipHeader: true});
        tokens.map((item) =>{
            XLSX.utils.sheet_add_json(ws,apiData[item.token],{origin:-1});
            XLSX.utils.sheet_add_json(ws,emptyArr,{origin:-1,skipHeader:true});
            XLSX.utils.sheet_add_json(ws,emptyArr,{origin:-1,skipHeader:true});
        })
        

        // XLSX.utils.sheet_add_json(ws,apiData.tripplePattiClose,{origin:'AG4'});
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      };

      return (
        <Button
          variant="contained"

          color="secondary" onClick={(e) => exportToCSV(apiData, filename,tokens)}>
              Export
        </Button>
      )
}