import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  ButtonGroup,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
} from "@material-ui/core";

import MenuBar from "../wrapper/MenuBar";

import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Alert, Pagination } from "@material-ui/lab";
import { Link, Redirect } from "react-router-dom";

import UserContext from "../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },

  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
    fontSize:'25px',
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  tableContainer: {
    flexDirection: "column",
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
   fontFamily: "Josefin Sans",
  },
  tableFont: {
   fontFamily: "Josefin Sans",
    color: "#000",
    fontSize:'20px',
  },
  paginate: {
    margin: theme.spacing(1),
  },
  search: {
    backgroundColor: "#fff",
   fontFamily: "Josefin Sans",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  tableFontTypo:{
    color:'#f44336',
    fontWeight:'bold',
    textDecoration:'none',
  },
  tableFontTypoBla:{
    color:'#000',
    fontWeight:'bold',
  },
  statusActive:{
    backgroundColor:'#4caf50',
    color:'#fff',
    fontFamily: "Josefin Sans",
    marginBottom:'5px',
  },
  chipInfo:{
    backgroundColor:"#f57c00",
    color:'#fff',
    fontFamily: "Josefin Sans",
    marginBottom:'5px',
  },
  chipRed:{
    backgroundColor:'#f44336',
    color:'#fff',
    fontFamily: "Josefin Sans",
    marginBottom:'5px',
  }
}));

export const Index = () => {
  const { user, refreshPage } = useContext(UserContext);

  const classes = useStyles();

  const [users, setUsers] = useState([]);

  const [pagination, setPagination] = useState([]);

  const [key, setKey] = useState("");

  const [page, setPage] = useState(1);

  //dialog state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  //bal
  const [addBal, setAddBal] = useState("");
  const [userName, setUserName] = useState();

  //bal err
  const [balErr, setBalErr] = useState(false);

  //backdrop
  const [backDrop, setBackDrop] = useState(false);

  //refresh page
  const [refresh, setRefresh] = useState(false);

  useEffect(async () => {
    const getUsers = await axios.post("searchUser?page=" + page, {
      key,
    });

    setUsers(getUsers.data.data);

    setPagination(getUsers.data);
    // console.log(getUsers.data);
  }, [page, key, refresh]);

  const handleChangePage = async (page) => {
    setPage(page);
    // console.log(page);
  };

  //add points funtion
  const addPoints = (id, user_name) => {
    setDialogOpen(true);
    setUserName(user_name);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleBalSubmit = async () => {
    setBackDrop(true);
    setRefresh(false);
    if (addBal === "") {
      setBackDrop(false);
      return setBalErr(true);
    }
    setDialogOpen(false);
    const balResponse = await axios.post("addBal", {
      userName,
      addBal,
    });

    console.log(balResponse);
    // console.log(balResponse);
    if (balResponse.status === 200) {
      refreshPage();
      setBackDrop(false);
      setRefresh(true);
      setUserName("");
    }
  };

  //activate deactivate user
  const userStatus = async (id, type) => {
    setRefresh(false);
    const response = await axios.post("userStatus", {
      id,
      type,
    });
    if (response.status === 200) {
      setRefresh(true);
    }
  };

  const deleteDialogClose = () => {
    setDeleteDialog(false);
    setBackDrop(false);
  };

  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "admin") {
    return <Redirect to="/admin" />;
  }
  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }

  return (
    <div className={classes.root}>
      <MenuBar data={user} />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Users
          </Typography>
        </Toolbar>
        <Divider />

        <TextField
          className={classes.search}
          value={key}
          onChange={(e) => setKey(e.target.value)}
          label="Search user"
          fullWidth
        />

        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  User
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Contact
                </TableCell>
             
                <TableCell align="center" className={classes.tableFont}>
                  Points
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Status
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                return (
                  <TableRow key={user.id}>
                    <TableCell align="center" className={classes.tableFont}>
                    Username: <span className={classes.tableFontTypo}>{user.user_name}</span> <br/>
                      Name: <span className={classes.tableFontTypoBla}>{user.name}</span> <br/>
                      Passbook: <Link className={classes.tableFontTypo} to={"/bookie/history/" + user.id}>{user.user_name}</Link> <br/>
                   
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                    <span className={classes.tableFontTypo}>{user.contact_no}</span> <br/>
                      {user.email}

                    </TableCell>
                
                    <TableCell align="center" className={classes.tableFont}>
                      {user.points}
                    </TableCell>

                    <TableCell align="center" className={classes.tableFont}>
                 
                    {user.status === "active" ? (
                      <Chip className={classes.statusActive} label={user.status} />
                        ) :  (
                          <Chip className={classes.chipRed} label={user.status} />
                       )}
                    </TableCell>

                    <TableCell align="center" className={classes.tableFont}>

                    <Chip className={classes.statusActive} label="Transfer Points" onClick={() => addPoints(user.id,user.user_name)} /><br/>
                   

                   {user.status === "active" ? (
                     <Chip className={classes.statusActive} label={user.status} onClick={() => userStatus(user.id, "deactive")} />
                       ) :  (
                         <Chip className={classes.chipRed} label={user.status} onClick={() => userStatus(user.id, "active")}/>
                      )}
                       <br/>
                   

                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          className={classes.paginate}
          page={page}
          count={pagination.last_page}
          component="div"
          color="primary"
          onChange={(e, page) => handleChangePage(page)}
        />
        <Divider />
        <Bottom />
        <BottomNav data={user} />
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar autoHideDuration={10000}>
          <Alert severity="success">Password Updated</Alert>
        </Snackbar>
 
        {/* add point dialog */}
        <Dialog open={dialogOpen} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Add point For <Chip label={userName} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter points.. For deduct use '-'
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Enter Points"
              type="number"
              fullWidth
              value={addBal}
              onChange={(e) => setAddBal(e.target.value)}
              error={balErr ? true : false}
              helperText={balErr ? "Please enter points" : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleBalSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </div>
  );
};
