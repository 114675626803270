import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import * as moment from "moment";

import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    textAlign: "center",
    flexDirection: "column",
  },
  playBtn: {
    justifyContent: "center",
    alignItems: "center",
  },
  gameHeader: {
    fontSize: "14",
    fontWeight: "bold",
   fontFamily: "Josefin Sans",
  },
  result: {
   fontFamily: "Josefin Sans",
    fontWeight: "bold",
  },
  btn: {
    backgroundColor: "#00b7fa",
  },
}));

export default function ResultCard({ data }) {
  var today = new Date();
  const curDay = new Date(today).toLocaleString("en-us", { weekday: "long" });

  const curTime = moment(today.toLocaleTimeString(), "HH:mm A").format("HH:mm");
  // console.log(today.toLocaleTimeString());

  const [runTime, setRunTime] = useState([]);
  const [playBtn, setPlayBtn] = useState(true);
  const [holiday, setHoliday] = useState(false);

  // console.log(curTime);
  const classes = useStyles();
  // console.log(data.game.runningTime);
  const runTimeJson = JSON.parse(data.game.runningTime);
  // const runStatus = data.status;
  // console.log(runTimeJson[0].day);

  useEffect(async () => {
    data.game.status === "running" ? setHoliday(false) : setHoliday(true);

    runTimeJson.map((res, i) => {
      if (res.day === curDay) {
        if (res.status === "open") {
          if (curTime >= "00:00" && curTime <= "05:00") {
            return setPlayBtn(false);
          }

          if (res.bidCloseTime >= curTime) {
            return setPlayBtn(true);
          } else {
            return setPlayBtn(false);
          }
        } else {
          setHoliday(true);
        }
      }
    });
    setRunTime(runTimeJson);
  }, []);

  // console.log(runTime);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          className={classes.gameHeader}
          color="textSecondary"
          gutterBottom
        >
          {data.game_name}
        </Typography>
        <Typography variant="h5" component="h2"></Typography>
        <Typography color="textSecondary" className={classes.result}>
          {data.open}-{data.jodi}-{data.close}
        </Typography>
      </CardContent>
      <CardActions className={classes.playBtn}>
        {holiday ? (
          <Typography color="textSecondary" className={classes.result}>
            Holiday
          </Typography>
        ) : playBtn ? (
          <Button
            variant="contained"
            className={classes.btn}
            color="secondary"
            size="small"
            component={Link}
            to={"/selectType/" + data.game_id}
          >
            Play
          </Button>
        ) : (
          <Typography color="textSecondary" className={classes.result}>
            Bid Time over
          </Typography>
        )}
      </CardActions>
    </Card>
  );
}
