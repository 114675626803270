import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";

import MenuBar from "../wrapper/MenuBar";

import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Alert } from "@material-ui/lab";
import { Redirect } from "react-router-dom";
import UserContext from "../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },

  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  form: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
  },
  field: {
    // margin: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submit: {
   fontFamily: "Josefin Sans",
    backgroundColor: "#00b7fa",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const Notification = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  //values
  const [notification, setNotification] = useState("");

  //backdrop
  const [backDrop, setbackDrop] = useState(false);

  //snackbar
  const [snackBar, setSnackBar] = useState(false);

  useEffect(async () => {
    const bookieReferal = await axios.get("notification");

    setNotification(bookieReferal.data.notification);

    // console.log(bookieReferal);
  }, []);

  const submitCode = async (e) => {
    setbackDrop(true);
    e.preventDefault();
    const res = await axios.post("notification", {
      notification,
    });
    console.log(res);

    setbackDrop(false);
    setSnackBar(true);
  };

  //snackbar close function
  const handleClose = () => {
    setSnackBar(false);
  };

  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "admin") {
    return <Redirect to="/admin" />;
  }
  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }
  return (
    <div className={classes.root}>
      <MenuBar />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Notification center
          </Typography>
        </Toolbar>
        <Divider />
        <form
          className={classes.form}
          noValidate
          onSubmit={submitCode}
          autoComplete="off"
        >
          <TextField
            fullWidth
            variant="outlined"
            label="Notification"
            className={classes.field}
            InputLabelProps={{
              shrink: true,
            }}
            value={notification}
            onChange={(e) => setNotification(e.target.value)}
            multiline
            rows={5}
          />

          <Button
            fullWidth
            variant="contained"
            className={classes.submit}
            color="secondary"
            type="submit"
          >
            Submit
          </Button>
        </form>

        <Divider />

        <Divider />
        <Bottom />
        <BottomNav />
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={snackBar}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Notification Updated
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};
