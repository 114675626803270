import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Divider, Fab, Button, Paper } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import axios from "axios";
import MenuBar from "./wrapper/MenuBar";
import ResultCard from "./inc/ResultCard";
import BottomNav from "./wrapper/BottomNav";
import GameRatio from "./inc/GameRatio";
import GameTime from "./inc/GameTime";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import AddHomeScr from "./AddHomeScr";

import Bottom from "./wrapper/Bottom";
import { Redirect } from "react-router-dom";

import UserContext from "./context/UserContext";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },
  bottom: {
    position: "fixed",
    bottom: "15%",
    right: "10%",
    color: "#fff",
    backgroundColor: "#25D366",
  },
  alert: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    margin: '10px',
    padding:'10px',

  },
}));

export const Home = () => {
  const { user, bookie, notify } = useContext(UserContext);

  const [results, setResults] = useState([]);


  useEffect(async () => {
    const response = await axios.get("/admin/result");


    setResults(response.data);
  }, []);

  const whatsApp = (msg) => {
    window.location.href =
      "//api.whatsapp.com/send?phone=91" +
      bookie.contact_no +
      "&text=" +
      msg +
      "";
  };

  const classes = useStyles();

  //if bookie suspended

  if (user.role === "bookie") {
    return <Redirect to="/bookie/index" />;
  }
  if (user.role === "admin") {
    return <Redirect to="/admin" />;
  }
  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }

  return (
    <div className={classes.root} >
      <MenuBar />
      <>
        <AddHomeScr />
        {user.points < 100 ? (
          <Alert severity="error" className={classes.alert}>
            Your acc bal is low please click{" "}
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() =>
                whatsApp(
                  "Hello sir add points username: " + user.user_name + ""
                )
              }
            >
              add money
            </Button>
          </Alert>
        ) : (
          ""
        )}

        {
          notify === '' ? '' : 
          (
            <Paper elevation={3} className={classes.alert}>
        {notify.notification}
     </Paper>)
        }

        {results.map((result) => (
          <ResultCard data={result} key={result.id} />
        ))}

        <GameRatio />

        <GameTime />
        <Divider />
        <Bottom />
        <BottomNav />

        {/* <Fab
          className={classes.bottom}
          aria-label="add"
          onClick={() => whatsApp("Hello admin sir")}
        >
          <WhatsAppIcon />
        </Fab> */}
      </>
    </div>
  );
};
