import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";

import MenuBar from "./wrapper/MenuBar";

import BottomNav from "./wrapper/BottomNav";

import axios from "axios";
import Bottom from "./wrapper/Bottom";
import { Alert, Pagination } from "@material-ui/lab";

import * as moment from "moment";

import UserContext from "./context/UserContext";
import { Redirect } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },

  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
  },
  subHead: {
    backgroundColor: "#fafafa",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  tableFont: {
   fontFamily: "Josefin Sans",
    color: "#000",
    fontWeight: "bold",
  },
  credit: {
    backgroundColor: "#42f548",
  },
}));


export const History = () => {
  const { user, gameTime } = useContext(UserContext);

  const classes = useStyles();

  var today = new Date();
  const curDay = new Date(today).toLocaleString("en-us", { weekday: "long" });
  const curTime = moment(today.toLocaleTimeString(), "HH:mm A").format("HH:mm");

  console.log(curTime);

  const [history, setHistory] = useState([]);
  const [pagination, setPagination] = useState([]);

  const [page, setPage] = useState(1);

  const [refresh, setRefresh] = useState(false);

  //backdrop
  const [backDrop, setbackDrop] = useState(false);

  //snackbar
  const [snackBar, setSnackBar] = useState(false);

  useEffect(async () => {
    const getHistory = await axios.get("history?page=" + page);

    setHistory(getHistory.data.data);
    setPagination(getHistory.data);
  }, [refresh, page]);

  const cancelGame = async (id) => {
    setbackDrop(true);
    const res = await axios.put("history/" + id, {
      type: "cancel",
    });
    setRefresh((v) => !v);
    setbackDrop(false);
  };
  //snackbar close function
  const handleClose = () => {
    setSnackBar(false);
  };

  const handleChangePage = async (page) => {
    setPage(page);
    // console.log(page);
  };

  const loadBtn = (resultStatus, playHistory, gameName, otc, gameType, id) => {
    if (resultStatus === null && playHistory === "Yes") {
      return gameTime.map((item) => {
        if (item.name === gameName) {
          const jsonTime = JSON.parse(item.runningTime);

          return jsonTime.map((jTime) => {
            if (jTime.day === curDay) {
              if (
                jTime.bidOpenTime >= curTime &&
                (otc === "open" || gameType === "jodi")
              ) {
                return (
                  <Button
                    variant="contained"
                    color="primary"
                    // onClick={() => cancelGame(id)}
                  >
                    Cancel n
                  </Button>
                );
              } else if (
                jTime.bidCloseTime >= curTime &&
                (otc === "close" || gameType !== "jodi")
              ) {
                return (
                  <Button
                    variant="contained"
                    color="primary"
                    // onClick={() => cancelGame(id)}
                  >
                    Cancel y
                  </Button>
                );
              }
            }
          });
        }
      });
    } else if (resultStatus === "Bid Cancelled by user") {
      return (
        <Button variant="contained" disabled color="primary">
          Cancelled
        </Button>
      );
    }
  };

  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }

  return (
    <div className={classes.root}>
      <MenuBar data={user} />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            History
          </Typography>
        </Toolbar>
        <Divider />

        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Description
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Type
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Played Number
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Points
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Balance
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Result
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Status
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Time
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((item) => {
                return (
                  <TableRow
                    key={item.id}
                    className={item.type === "Credit" ? classes.credit : ""}
                  >
                    {item.playHistory === "Yes" ? (
                      <TableCell align="center" className={classes.tableFont}>
                        PLAYED {item.gameName} {item.gameType} {item.otc}
                      </TableCell>
                    ) : (
                      <TableCell align="center" className={classes.tableFont}>
                        {item.description}
                      </TableCell>
                    )}

                    <TableCell align="center" className={classes.tableFont}>
                      {item.type}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.played_no}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.points}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.balance}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.result}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.resultStatus}
                    </TableCell>

                    <TableCell align="center" className={classes.tableFont}>
                      {moment(item.created_at).format("llll")}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {/* {loadBtn(
                        item.resultStatus,
                        item.playHistory,
                        item.gameName,
                        item.otc,
                        item.gameType,
                        item.id
                      )} */}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          className={classes.paginate}
          page={page}
          count={pagination.last_page}
          component="div"
          color="primary"
          onChange={(e, page) => handleChangePage(page)}
        />
        <Bottom />
        <BottomNav data={user} />
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={snackBar}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Withdraw request added !
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};
