import React from "react";
import { Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  bottom: {
    marginBottom: 70,
    marginTop: 10,
  },
}));
export default function Bottom() {
  const classes = useStyles();
  return (
    <Toolbar className={classes.bottom}>
      <Typography variant="h6" className={classes.text}>
        Made With love
      </Typography>
    </Toolbar>
  );
}
