import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import axios from "axios";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#00b7fa',
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontFamily: "Josefin Sans",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#00b7fa",
    fontFamily: "Josefin Sans",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  font:{
    fontFamily: "Josefin Sans",
  }
}));

export default function Login(props) {
  const classes = useStyles();

  const [name, setName] = useState();
  const [password, setPassword] = useState();

  const [error, setError] = useState(false);
  const [auth, setAuth] = useState(false);

  const [backDrop, setBackDrop] = useState(false);
  const [redirect, setDirect] = useState(false);

  useEffect(() => {
    const getAuth = localStorage.getItem("token");
    if (getAuth !== null) {
      setAuth(true);
    }
    // console.log(getAuth);
  }, []);
  const handleSubmit = async (e) => {
    setBackDrop(true);
    e.preventDefault();
    axios
      .post(
        "login",
        {
          name,
          password,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        setError(false);
        setBackDrop(false);
        localStorage.setItem("token", response.data.token);
        setDirect(true);
        window.location.reload();
        // console.log(response.data);
      })
      .catch(function (error) {
        setError(true);
        setBackDrop(false);

        // console.log(error.response.data.errors);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
  };

  if (redirect) {
    return <Redirect to="/" />;
  }

  if (auth) {
    return <Redirect to="/" />;
  }
  return (
    <Container component="main" maxWidth="xs">
      <Backdrop className={classes.backdrop} open={backDrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={error} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Username/Mobile or Password is Wrong
        </Alert>
      </Snackbar>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" className={classes.font} variant="h5">
          Sign in
        </Typography>
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Username / Mobile number"
            name="name"
            autoFocus
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container >
            <Grid item xs >
              <Link href="#" variant="body2" className={classes.font}>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2" className={classes.font}>
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}></Box>
    </Container>
  );
}
