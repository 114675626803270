import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { useParams, Redirect } from "react-router-dom";
import * as moment from "moment";
import CustomDialog from "./inc/CustomDialog";

import {
  Divider,
  Typography,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  TextField,
  Button,
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  FormHelperText,
  Dialog,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";

import axios from "axios";
import MenuBar from "./wrapper/MenuBar";

import BottomNav from "./wrapper/BottomNav";

import UserContext from "./context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
   fontFamily: "Josefin Sans",
  },

  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#f44336",
    fontWeight: "bold",
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  gridClass: {
    textAlign: "center",
    flexDirection: "column",
  },
  addbtn: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
   fontFamily: "Josefin Sans",
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  fontStyle: {
   fontFamily: "Josefin Sans",
  },

  tableContainer: {
    flexDirection: "column",
    marginTop: 50,
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
   fontFamily: "Josefin Sans",
  },
  tableFont: {
   fontFamily: "Josefin Sans",
    color: "#000",
    fontWeight: "bold",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  bottom: {
    marginBottom: 50,
  },
  text: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  submit: {
   fontFamily: "Josefin Sans",
    backgroundColor: "#f44336",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const PlayForm = () => {
  const { user, refreshPage } = useContext(UserContext);

  const classes = useStyles();

  let { gid } = useParams();

  const [openDisable, setOpenDisable] = useState(false);
  const [closeDisable, setCloseDisable] = useState(false);

  const [playArray, setPlayArray] = useState([]);

  const [playType, setPlayType] = useState("");
  const [ocType, setOcType] = useState("");
  const [number, setNumber] = useState("");
  const [amt, setAmt] = useState(0);
  const [arrayId, setArrayId] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0); //total amout

  const [gameTime, setGameTime] = useState([]); //game time response from axios
  const [holiday, setHoliday] = useState(false);
  const [bidTimeOver, setBidTimeOver] = useState(false);

  //validation err
  const [amtErr, setAmtErr] = useState(false);
  const [playTypeErr, setPlayTypeErr] = useState(false);
  const [ocTypeErr, setOcTypeErr] = useState(false);
  const [numberErr, setNumberErr] = useState(false);

  //Dialog open
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogArr, setDialogArr] = useState([]);

  //backdrop
  const [backDrop, setbackDrop] = useState(false);

  var today = new Date();
  const curDay = new Date(today).toLocaleString("en-us", { weekday: "long" });
  const curTime = moment(today.toLocaleTimeString(), "HH:mm A").format("HH:mm");

  useEffect(async () => {
    const game = await axios.get("/admin/games/" + gid);
    const runTimeJson = JSON.parse(game.data.runningTime);
    setGameTime(game.data);

    game.data.status === "running" ? setHoliday(false) : setHoliday(true);
    // console.log(curTime);
    runTimeJson.map((res, i) => {
      if (res.day === curDay) {
        if (res.status === "open") {
          if (curTime >= "00:00" && curTime <= "05:00") {
            return setBidTimeOver(true);
          }
          // console.log(res.bidCloseTime);
          res.bidCloseTime <= curTime
            ? setBidTimeOver(true)
            : setBidTimeOver(false);

          res.bidOpenTime <= curTime
            ? setOpenDisable(true)
            : setOpenDisable(false);
        } else {
          setHoliday(true);
          // console.log("work");
        }
      }
    });
    // console.log(runTimeJson);
  }, []);

  const addGame = async (e) => {
    e.preventDefault();

    if (playType === "") {
      return setPlayTypeErr(true);
    }
    if (ocType === "") {
      return setOcTypeErr(true);
    }
    if (number === "") {
      return setNumberErr(true);
    }
    if (amt === 0 || amt < 10) {
      return setAmtErr(true);
    }
    setPlayTypeErr(false);

    setOcTypeErr(false);

    setNumberErr(false);

    setAmtErr(false);

    const array = {
      arrayId,
      playType,
      ocType,
      number,
      amt,
    };

    setPlayArray([...playArray, array]);
    setArrayId(arrayId + 1);
    // console.log(array);
  };

  const dialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const total = playArray.reduce(
      (tAmt, item) => tAmt + parseInt(item.amt),
      0
    );
    setGrandTotal(total);
  }, [playArray]);

  const handleDelete = async (id) => {
    // console.log(id);
    const newArray = playArray.filter((item) => item.arrayId !== id);
    setPlayArray(newArray);
    // totalAmt();
  };
  // console.log(playArray);
  const sbmitGame = async () => {
    setbackDrop(true);
    if (parseInt(user.points) < grandTotal) {
      const errArr = {
        title: "Insufficient points",
        msg: "You donot have sufficient Points.",
        type: "error",
      };
      setDialogArr(errArr);
      setbackDrop(false);

      return setDialogOpen(true);
    }

    if (playArray.length === 0) {
      const errArr = {
        title: "Add game",
        msg: "Please add game then press submit..",
        type: "error",
      };
      setDialogArr(errArr);
      setbackDrop(false);

      return setDialogOpen(true);
    }

    const responce = await axios.post("play", {
      playArray,
      gameName: gameTime.name,
      gameId: gameTime.id,
    });

    console.log(responce);
    if (responce.status === 200) {
      const errArr = {
        title: responce.data[0],
        msg: responce.data[1],
        type: "error",
      };
      setDialogArr(errArr);
      setbackDrop(false);
      setDialogOpen(true);
    }
    if (responce.status === 202) {
      const errArr = {
        title: responce.data[0],
        msg: responce.data[1],
        type: "success",
      };
      refreshPage();
      setDialogArr(errArr);
      setbackDrop(false);
      setDialogOpen(true);
    }
  };

  const loadNumber = (type) => {
    switch (type) {
      case "single":
        return (
          <Select
            defaultValue=""
            id="grouped-select"
            onChange={(e) => setNumber(e.target.value)}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
          </Select>
        );
        break;
      case "jodi":
        return (
          <Select
            defaultValue=""
            id="grouped-select"
            onChange={(e) => setNumber(e.target.value)}
          >
            <MenuItem value="00">00</MenuItem>
            <MenuItem value="01">01</MenuItem>
            <MenuItem value="02">02</MenuItem>
            <MenuItem value="03">03</MenuItem>
            <MenuItem value="04">04</MenuItem>
            <MenuItem value="05">05</MenuItem>
            <MenuItem value="06">06</MenuItem>
            <MenuItem value="07">07</MenuItem>
            <MenuItem value="08">08</MenuItem>
            <MenuItem value="09">09</MenuItem>
            <MenuItem value="10">10</MenuItem>
            <MenuItem value="11">11</MenuItem>
            <MenuItem value="12">12</MenuItem>
            <MenuItem value="13">13</MenuItem>
            <MenuItem value="14">14</MenuItem>
            <MenuItem value="15">15</MenuItem>
            <MenuItem value="16">16</MenuItem>
            <MenuItem value="17">17</MenuItem>
            <MenuItem value="18">18</MenuItem>
            <MenuItem value="19">19</MenuItem>
            <MenuItem value="20">20</MenuItem>
            <MenuItem value="21">21</MenuItem>
            <MenuItem value="22">22</MenuItem>
            <MenuItem value="23">23</MenuItem>
            <MenuItem value="24">24</MenuItem>
            <MenuItem value="25">25</MenuItem>
            <MenuItem value="26">26</MenuItem>
            <MenuItem value="27">27</MenuItem>
            <MenuItem value="28">28</MenuItem>
            <MenuItem value="29">29</MenuItem>
            <MenuItem value="30">30</MenuItem>
            <MenuItem value="31">31</MenuItem>
            <MenuItem value="32">32</MenuItem>
            <MenuItem value="33">33</MenuItem>
            <MenuItem value="34">34</MenuItem>
            <MenuItem value="35">35</MenuItem>
            <MenuItem value="36">36</MenuItem>
            <MenuItem value="37">37</MenuItem>
            <MenuItem value="38">38</MenuItem>
            <MenuItem value="39">39</MenuItem>
            <MenuItem value="40">40</MenuItem>
            <MenuItem value="41">41</MenuItem>
            <MenuItem value="42">42</MenuItem>
            <MenuItem value="43">43</MenuItem>
            <MenuItem value="44">44</MenuItem>
            <MenuItem value="45">45</MenuItem>
            <MenuItem value="46">46</MenuItem>
            <MenuItem value="47">47</MenuItem>
            <MenuItem value="48">48</MenuItem>
            <MenuItem value="49">49</MenuItem>
            <MenuItem value="50">50</MenuItem>
            <MenuItem value="51">51</MenuItem>
            <MenuItem value="52">52</MenuItem>
            <MenuItem value="53">53</MenuItem>
            <MenuItem value="54">54</MenuItem>
            <MenuItem value="55">55</MenuItem>
            <MenuItem value="56">56</MenuItem>
            <MenuItem value="57">57</MenuItem>
            <MenuItem value="58">58</MenuItem>
            <MenuItem value="59">59</MenuItem>
            <MenuItem value="60">60</MenuItem>
            <MenuItem value="61">61</MenuItem>
            <MenuItem value="62">62</MenuItem>
            <MenuItem value="63">63</MenuItem>
            <MenuItem value="64">64</MenuItem>
            <MenuItem value="65">65</MenuItem>
            <MenuItem value="66">66</MenuItem>
            <MenuItem value="67">67</MenuItem>
            <MenuItem value="68">68</MenuItem>
            <MenuItem value="69">69</MenuItem>
            <MenuItem value="70">70</MenuItem>
            <MenuItem value="71">71</MenuItem>
            <MenuItem value="72">72</MenuItem>
            <MenuItem value="73">73</MenuItem>
            <MenuItem value="74">74</MenuItem>
            <MenuItem value="75">75</MenuItem>
            <MenuItem value="76">76</MenuItem>
            <MenuItem value="77">77</MenuItem>
            <MenuItem value="78">78</MenuItem>
            <MenuItem value="79">79</MenuItem>
            <MenuItem value="80">80</MenuItem>
            <MenuItem value="81">81</MenuItem>
            <MenuItem value="82">82</MenuItem>
            <MenuItem value="83">83</MenuItem>
            <MenuItem value="84">84</MenuItem>
            <MenuItem value="85">85</MenuItem>
            <MenuItem value="86">86</MenuItem>
            <MenuItem value="87">87</MenuItem>
            <MenuItem value="88">88</MenuItem>
            <MenuItem value="89">89</MenuItem>
            <MenuItem value="90">90</MenuItem>
            <MenuItem value="91">91</MenuItem>
            <MenuItem value="92">92</MenuItem>
            <MenuItem value="93">93</MenuItem>
            <MenuItem value="94">94</MenuItem>
            <MenuItem value="95">95</MenuItem>
            <MenuItem value="96">96</MenuItem>
            <MenuItem value="97">97</MenuItem>
            <MenuItem value="98">98</MenuItem>
            <MenuItem value="99">99</MenuItem>
          </Select>
        );
        break;

      case "singlePatti":
        return (
          <Select
            defaultValue=""
            id="grouped-select"
            onChange={(e) => setNumber(e.target.value)}
          >
            <MenuItem value={0} disabled>
              1
            </MenuItem>
            <MenuItem value={128}>128</MenuItem>
            <MenuItem value={137}>137</MenuItem>
            <MenuItem value={146}>146</MenuItem>
            <MenuItem value={236}>236</MenuItem>
            <MenuItem value={245}>245</MenuItem>
            <MenuItem value={290}>290</MenuItem>
            <MenuItem value={380}>380</MenuItem>
            <MenuItem value={470}>470</MenuItem>
            <MenuItem value={489}>489</MenuItem>
            <MenuItem value={560}>560</MenuItem>
            <MenuItem value={579}>579</MenuItem>
            <MenuItem value={678}>678</MenuItem>

            <MenuItem value={0} disabled>
              2
            </MenuItem>
            <MenuItem value={129}>129</MenuItem>
            <MenuItem value={138}>138</MenuItem>
            <MenuItem value={147}>147</MenuItem>
            <MenuItem value={156}>156</MenuItem>
            <MenuItem value={237}>237</MenuItem>
            <MenuItem value={246}>246</MenuItem>
            <MenuItem value={345}>345</MenuItem>
            <MenuItem value={390}>390</MenuItem>
            <MenuItem value={480}>480</MenuItem>
            <MenuItem value={570}>570</MenuItem>
            <MenuItem value={589}>589</MenuItem>
            <MenuItem value={679}>679</MenuItem>

            <MenuItem value={0} disabled>
              3
            </MenuItem>
            <MenuItem value={120}>120</MenuItem>
            <MenuItem value={139}>139</MenuItem>
            <MenuItem value={148}>148</MenuItem>
            <MenuItem value={157}>157</MenuItem>
            <MenuItem value={238}>238</MenuItem>
            <MenuItem value={247}>247</MenuItem>
            <MenuItem value={256}>256</MenuItem>
            <MenuItem value={346}>346</MenuItem>
            <MenuItem value={490}>490</MenuItem>
            <MenuItem value={580}>580</MenuItem>
            <MenuItem value={670}>670</MenuItem>
            <MenuItem value={689}>689</MenuItem>

            <MenuItem value={0} disabled>
              4
            </MenuItem>
            <MenuItem value={130}>130</MenuItem>
            <MenuItem value={149}>149</MenuItem>
            <MenuItem value={158}>158</MenuItem>
            <MenuItem value={167}>167</MenuItem>
            <MenuItem value={239}>239</MenuItem>
            <MenuItem value={248}>248</MenuItem>
            <MenuItem value={257}>257</MenuItem>
            <MenuItem value={347}>347</MenuItem>
            <MenuItem value={356}>356</MenuItem>
            <MenuItem value={590}>590</MenuItem>
            <MenuItem value={680}>680</MenuItem>
            <MenuItem value={789}>789</MenuItem>

            <MenuItem value={0} disabled>
              5
            </MenuItem>
            <MenuItem value={140}>140</MenuItem>
            <MenuItem value={159}>159</MenuItem>
            <MenuItem value={168}>168</MenuItem>
            <MenuItem value={230}>230</MenuItem>
            <MenuItem value={249}>249</MenuItem>
            <MenuItem value={258}>258</MenuItem>
            <MenuItem value={267}>267</MenuItem>
            <MenuItem value={348}>348</MenuItem>
            <MenuItem value={357}>357</MenuItem>
            <MenuItem value={456}>456</MenuItem>
            <MenuItem value={690}>690</MenuItem>
            <MenuItem value={780}>780</MenuItem>

            <MenuItem value={0} disabled>
              6
            </MenuItem>
            <MenuItem value={123}>123</MenuItem>
            <MenuItem value={150}>150</MenuItem>
            <MenuItem value={169}>169</MenuItem>
            <MenuItem value={178}>178</MenuItem>
            <MenuItem value={240}>240</MenuItem>
            <MenuItem value={259}>259</MenuItem>
            <MenuItem value={268}>268</MenuItem>
            <MenuItem value={349}>349</MenuItem>
            <MenuItem value={358}>358</MenuItem>
            <MenuItem value={367}>367</MenuItem>
            <MenuItem value={457}>457</MenuItem>
            <MenuItem value={790}>790</MenuItem>

            <MenuItem value={0} disabled>
              7
            </MenuItem>
            <MenuItem value={124}>124</MenuItem>
            <MenuItem value={160}>160</MenuItem>
            <MenuItem value={179}>179</MenuItem>
            <MenuItem value={250}>250</MenuItem>
            <MenuItem value={269}>269</MenuItem>
            <MenuItem value={278}>278</MenuItem>
            <MenuItem value={340}>340</MenuItem>
            <MenuItem value={359}>359</MenuItem>
            <MenuItem value={368}>368</MenuItem>
            <MenuItem value={458}>458</MenuItem>
            <MenuItem value={467}>467</MenuItem>
            <MenuItem value={890}>890</MenuItem>

            <MenuItem value={0} disabled>
              8
            </MenuItem>
            <MenuItem value={125}>125</MenuItem>
            <MenuItem value={134}>134</MenuItem>
            <MenuItem value={170}>170</MenuItem>
            <MenuItem value={189}>189</MenuItem>
            <MenuItem value={260}>260</MenuItem>
            <MenuItem value={279}>279</MenuItem>
            <MenuItem value={350}>350</MenuItem>
            <MenuItem value={369}>369</MenuItem>
            <MenuItem value={378}>378</MenuItem>
            <MenuItem value={459}>459</MenuItem>
            <MenuItem value={468}>468</MenuItem>
            <MenuItem value={567}>567</MenuItem>

            <MenuItem value={0} disabled>
              9
            </MenuItem>
            <MenuItem value={126}>126</MenuItem>
            <MenuItem value={135}>135</MenuItem>
            <MenuItem value={180}>180</MenuItem>
            <MenuItem value={234}>234</MenuItem>
            <MenuItem value={270}>270</MenuItem>
            <MenuItem value={289}>289</MenuItem>
            <MenuItem value={360}>360</MenuItem>
            <MenuItem value={379}>379</MenuItem>
            <MenuItem value={450}>450</MenuItem>
            <MenuItem value={469}>469</MenuItem>
            <MenuItem value={478}>478</MenuItem>
            <MenuItem value={568}>568</MenuItem>

            <MenuItem value={0} disabled>
              0
            </MenuItem>
            <MenuItem value={127}>127</MenuItem>
            <MenuItem value={136}>136</MenuItem>
            <MenuItem value={145}>145</MenuItem>
            <MenuItem value={190}>190</MenuItem>
            <MenuItem value={235}>235</MenuItem>
            <MenuItem value={280}>280</MenuItem>
            <MenuItem value={389}>389</MenuItem>
            <MenuItem value={370}>370</MenuItem>
            <MenuItem value={460}>460</MenuItem>
            <MenuItem value={479}>479</MenuItem>
            <MenuItem value={569}>569</MenuItem>
            <MenuItem value={578}>578</MenuItem>
          </Select>
        );
        break;

      case "doublePatti":
        return (
          <Select
            defaultValue=""
            id="grouped-select"
            onChange={(e) => setNumber(e.target.value)}
          >
            <MenuItem value={0} disabled>
              1
            </MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={119}>119</MenuItem>
            <MenuItem value={155}>155</MenuItem>
            <MenuItem value={227}>227</MenuItem>
            <MenuItem value={335}>335</MenuItem>
            <MenuItem value={344}>344</MenuItem>
            <MenuItem value={399}>399</MenuItem>
            <MenuItem value={588}>588</MenuItem>
            <MenuItem value={669}>669</MenuItem>

            <MenuItem value={0} disabled>
              2
            </MenuItem>
            <MenuItem value={110}>110</MenuItem>
            <MenuItem value={200}>200</MenuItem>
            <MenuItem value={228}>228</MenuItem>
            <MenuItem value={255}>255</MenuItem>
            <MenuItem value={336}>336</MenuItem>
            <MenuItem value={499}>499</MenuItem>
            <MenuItem value={660}>660</MenuItem>
            <MenuItem value={688}>688</MenuItem>
            <MenuItem value={778}>778</MenuItem>

            <MenuItem value={0} disabled>
              3
            </MenuItem>
            <MenuItem value={166}>166</MenuItem>
            <MenuItem value={229}>229</MenuItem>
            <MenuItem value={300}>300</MenuItem>
            <MenuItem value={337}>337</MenuItem>
            <MenuItem value={355}>355</MenuItem>
            <MenuItem value={445}>445</MenuItem>
            <MenuItem value={599}>599</MenuItem>
            <MenuItem value={779}>779</MenuItem>
            <MenuItem value={788}>788</MenuItem>

            <MenuItem value={0} disabled>
              4
            </MenuItem>
            <MenuItem value={112}>112</MenuItem>
            <MenuItem value={220}>220</MenuItem>
            <MenuItem value={266}>266</MenuItem>
            <MenuItem value={338}>338</MenuItem>
            <MenuItem value={400}>400</MenuItem>
            <MenuItem value={446}>446</MenuItem>
            <MenuItem value={455}>455</MenuItem>
            <MenuItem value={699}>699</MenuItem>
            <MenuItem value={770}>770</MenuItem>

            <MenuItem value={0} disabled>
              5
            </MenuItem>
            <MenuItem value={113}>113</MenuItem>
            <MenuItem value={122}>122</MenuItem>
            <MenuItem value={177}>177</MenuItem>
            <MenuItem value={339}>339</MenuItem>
            <MenuItem value={366}>366</MenuItem>
            <MenuItem value={447}>447</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={799}>799</MenuItem>
            <MenuItem value={889}>889</MenuItem>

            <MenuItem value={0} disabled>
              6
            </MenuItem>
            <MenuItem value={114}>114</MenuItem>
            <MenuItem value={277}>277</MenuItem>
            <MenuItem value={330}>330</MenuItem>
            <MenuItem value={448}>448</MenuItem>
            <MenuItem value={466}>466</MenuItem>
            <MenuItem value={556}>556</MenuItem>
            <MenuItem value={600}>600</MenuItem>
            <MenuItem value={880}>880</MenuItem>
            <MenuItem value={899}>899</MenuItem>

            <MenuItem value={0} disabled>
              7
            </MenuItem>
            <MenuItem value={115}>115</MenuItem>
            <MenuItem value={133}>133</MenuItem>
            <MenuItem value={188}>188</MenuItem>
            <MenuItem value={223}>223</MenuItem>
            <MenuItem value={377}>377</MenuItem>
            <MenuItem value={449}>449</MenuItem>
            <MenuItem value={557}>557</MenuItem>
            <MenuItem value={566}>566</MenuItem>
            <MenuItem value={700}>700</MenuItem>

            <MenuItem value={0} disabled>
              8
            </MenuItem>
            <MenuItem value={116}>116</MenuItem>
            <MenuItem value={224}>224</MenuItem>
            <MenuItem value={233}>233</MenuItem>
            <MenuItem value={288}>288</MenuItem>
            <MenuItem value={440}>440</MenuItem>
            <MenuItem value={477}>477</MenuItem>
            <MenuItem value={558}>558</MenuItem>
            <MenuItem value={800}>800</MenuItem>
            <MenuItem value={990}>990</MenuItem>

            <MenuItem value={0} disabled>
              9
            </MenuItem>
            <MenuItem value={117}>117</MenuItem>
            <MenuItem value={144}>144</MenuItem>
            <MenuItem value={199}>199</MenuItem>
            <MenuItem value={225}>225</MenuItem>
            <MenuItem value={388}>388</MenuItem>
            <MenuItem value={559}>559</MenuItem>
            <MenuItem value={577}>577</MenuItem>
            <MenuItem value={667}>667</MenuItem>
            <MenuItem value={900}>900</MenuItem>

            <MenuItem value={0} disabled>
              0
            </MenuItem>
            <MenuItem value={118}>118</MenuItem>
            <MenuItem value={226}>226</MenuItem>
            <MenuItem value={244}>244</MenuItem>
            <MenuItem value={299}>299</MenuItem>
            <MenuItem value={334}>334</MenuItem>
            <MenuItem value={488}>488</MenuItem>
            <MenuItem value={550}>550</MenuItem>
            <MenuItem value={677}>677</MenuItem>
            <MenuItem value={668}>668</MenuItem>
          </Select>
        );
        break;

      case "tripplePatti":
        return (
          <Select
            defaultValue=""
            id="grouped-select"
            onChange={(e) => setNumber(e.target.value)}
          >
            <MenuItem value="000">000</MenuItem>
            <MenuItem value={111}>111</MenuItem>
            <MenuItem value={222}>222</MenuItem>
            <MenuItem value={333}>333</MenuItem>
            <MenuItem value={444}>444</MenuItem>
            <MenuItem value={555}>555</MenuItem>
            <MenuItem value={666}>666</MenuItem>
            <MenuItem value={777}>777</MenuItem>
            <MenuItem value={888}>888</MenuItem>
            <MenuItem value={999}>999</MenuItem>
          </Select>
        );
        break;

      default:
        break;
    }
  };

  if (user.role === "bookie") {
    return <Redirect to="/bookie/index" />;
  }
  if (user.role === "admin") {
    return <Redirect to="/admin" />;
  }

  return (
    <div className={classes.root} overflow="hidden">
      <MenuBar data={user} />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            {holiday
              ? "Today Is holiday for " + gameTime.name
              : bidTimeOver
              ? "Bid Time Over for " + gameTime.name
              : gameTime.name}
          </Typography>
        </Toolbar>
        <Divider />

        <form className={classes.form} noValidate onSubmit={addGame}>
          <Grid container>
            <Grid item xs={6} sm={6}>
              <FormControl
                className={classes.formControl}
                error={playTypeErr ? true : false}
              >
                <InputLabel htmlFor="playType">Select Play Type</InputLabel>
                <Select
                  defaultValue=""
                  id="playType"
                  onChange={(e) => {
                    setPlayType(e.target.value);
                  }}
                >
                  <MenuItem value="single">Single</MenuItem>
                  {openDisable ? (
                    <MenuItem value="jodi" disabled>
                      Jodi
                    </MenuItem>
                  ) : (
                    <MenuItem value="jodi">Jodi</MenuItem>
                  )}

                  <MenuItem value="singlePatti">Single Patti</MenuItem>
                  <MenuItem value="doublePatti">Double Patti</MenuItem>
                  <MenuItem value="tripplePatti">Tripple Patti</MenuItem>
                </Select>
                <FormHelperText>
                  {playTypeErr ? "Please select Play type" : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControl
                className={classes.formControl}
                error={ocTypeErr ? true : false}
              >
                <InputLabel htmlFor="grouped-select">
                  Select Open/Close
                </InputLabel>
                {playType === "jodi" ? (
                  <Select
                    defaultValue="close"
                    id="grouped-select"
                    onChange={(e) => setOcType(e.target.value)}
                  >
                    {closeDisable ? (
                      <MenuItem value="close" disabled>
                        Close
                      </MenuItem>
                    ) : (
                      <MenuItem value="close">Close</MenuItem>
                    )}
                  </Select>
                ) : (
                  <Select
                    defaultValue=""
                    id="grouped-select"
                    onChange={(e) => setOcType(e.target.value)}
                  >
                    {openDisable ? (
                      <MenuItem value="open" disabled>
                        Open
                      </MenuItem>
                    ) : (
                      <MenuItem value="open">Open</MenuItem>
                    )}
                    {closeDisable ? (
                      <MenuItem value="close" disabled>
                        Close
                      </MenuItem>
                    ) : (
                      <MenuItem value="close">Close</MenuItem>
                    )}
                  </Select>
                )}

                <FormHelperText>
                  {ocTypeErr ? "Please select Open/Close type" : ""}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={4} sm={4}>
              <FormControl
                className={classes.formControl}
                error={numberErr ? true : false}
              >
                <InputLabel htmlFor="grouped-select">Number</InputLabel>
                {/* select */}
                {loadNumber(playType)}
                <FormHelperText>
                  {numberErr ? "Please select Number" : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={4}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="amount"
                  autoComplete="none"
                  label="Amount"
                  type="number"
                  onChange={(e) => setAmt(e.target.value)}
                  autoComplete="off"
                  error={amtErr ? true : false}
                  helperText={amtErr ? "Min bid is 10 rs" : ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={4} className={classes.gridClass}>
              <Button
                variant="contained"
                size="small"
                type="submit"
                className={classes.addbtn}
                color="primary"
                disabled={holiday ? true : bidTimeOver ? true : false}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </form>

        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  GameType
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Number
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Amount
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {playArray
                .map((val) => {
                  return (
                    <TableRow>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        className={classes.tableFont}
                      >
                        {val.playType}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                        {val.number}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                        {val.amt}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                        <Button
                          onClick={() => {
                            handleDelete(val.arrayId);
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
                .reverse()}
              <TableRow>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  className={classes.tableFont}
                ></TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Total
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  {grandTotal}
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.tableFont}
                ></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="contained"
          className={classes.submit}
          color="secondary"
          fullWidth={true}
          disabled={holiday ? true : bidTimeOver ? true : false}
          onClick={sbmitGame}
        >
          Submit Game
        </Button>
        <Toolbar className={classes.bottom}>
          <Typography variant="h6" className={classes.text}>
            Made With love
          </Typography>
        </Toolbar>
        <BottomNav data={user} />
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CustomDialog data={dialogArr} close={dialogClose} />
        </Dialog>
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    </div>
  );
};
