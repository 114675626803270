import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, Redirect } from "react-router-dom";
import {
  Divider,
  Typography,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";

import MenuBar from "../wrapper/MenuBar";

import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Alert, Pagination } from "@material-ui/lab";

import * as moment from "moment";
import UserContext from "../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },

  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#f44336",
    fontWeight: "bold",
  },
  subHead: {
    backgroundColor: "#fafafa",
  },

  tableFont: {
   fontFamily: "Josefin Sans",
    color: "#000",
    fontWeight: "bold",
  },
  credit: {
    backgroundColor: "#42f548",
  },
  tableContainer: {
    flexDirection: "column",
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
   fontFamily: "Josefin Sans",
  },
  tableFont: {
   fontFamily: "Josefin Sans",
    color: "#000",
    fontWeight: "bold",
  },
  paginate: {
    margin: theme.spacing(1),
  },
}));

export const TokenHistory = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  let { uid } = useParams();

  var today = new Date();
  const curDay = new Date(today).toLocaleString("en-us", { weekday: "long" });
  const curTime = moment(today.toLocaleTimeString(), "HH:mm:ss").format(
    "HH:mm"
  );

  const [history, setHistory] = useState([]);
  const [pagination, setPagination] = useState([]);

  const [page, setPage] = useState(1);

  const [refresh, setRefresh] = useState(false);

  useEffect(async () => {
    const userhist = await axios.get("/tokenHistory/" + uid + "?page=" + page);

    setHistory(userhist.data.data);
    setPagination(userhist.data);

    // console.log(userhist);
  }, [refresh, page]);
  const handleChangePage = async (page) => {
    setPage(page);
    // console.log(page);
  };

  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "admin") {
    return <Redirect to="/admin" />;
  }
  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }

  return (
    <div className={classes.root}>
      <MenuBar />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            History
          </Typography>
        </Toolbar>
        <Divider />

        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Description
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Type
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Played Number
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Points
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Balance
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Result
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Status
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Time
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((item) => {
                return (
                  <TableRow
                    className={item.type === "Credit" ? classes.credit : ""}
                  >
                    {item.playHistory === "Yes" ? (
                      <TableCell align="center" className={classes.tableFont}>
                        PLAYED {item.gameName} {item.gameType} {item.otc}
                      </TableCell>
                    ) : (
                      <TableCell align="center" className={classes.tableFont}>
                        {item.description}
                      </TableCell>
                    )}

                    <TableCell align="center" className={classes.tableFont}>
                      {item.type}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.played_no}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.points}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.balance}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.result}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.resultStatus}
                    </TableCell>

                    <TableCell align="center" className={classes.tableFont}>
                      {moment(item.created_at).format("llll")}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          className={classes.paginate}
          page={page}
          count={pagination.last_page}
          component="div"
          color="primary"
          onChange={(e, page) => handleChangePage(page)}
        />
        <Bottom />
        <BottomNav />
      </>
    </div>
  );
};
