import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";

import MenuBar from "./wrapper/MenuBar";

import BottomNav from "./wrapper/BottomNav";

import axios from "axios";
import Bottom from "./wrapper/Bottom";
import { Alert } from "@material-ui/lab";

import UserContext from "./context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },

  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
    fontSize:'25px',
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  form: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
  },
  field: {
    // margin: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submit: {
   fontFamily: "Josefin Sans",
    backgroundColor: "#00b7fa",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const Password = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  //user account details
  const [password, setPassword] = useState("");
  const [rePassword, setRepassword] = useState("");

  //err
  const [passErr, setPassErr] = useState(false);
  const [rePassErr, setRePassErr] = useState(false);

  //backdrop
  const [backDrop, setbackDrop] = useState(false);

  //snackbar
  const [snackBar, setSnackBar] = useState(false);

  const submitBank = async (e) => {
    setbackDrop(true);
    e.preventDefault();
    // console.log(password);
    if (password === "") {
      setbackDrop(false);
      return setPassErr(true);
    }
    if (rePassword === "") {
      setbackDrop(false);
      return setRePassErr(true);
    }
    if (password !== rePassword) {
      setbackDrop(false);
      return setRePassErr(false);
    }

    setPassErr(false);
    setRePassErr(false);

    const response = await axios.put("user/" + user.id, {
      type: "pass",
      password,
    });
    if (response.status === 202) {
      setbackDrop(false);
      setSnackBar(true);
    }
  };
  //snackbar close function
  const handleClose = () => {
    setSnackBar(false);
  };

  return (
    <div className={classes.root}>
      <MenuBar />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Change Password
          </Typography>
        </Toolbar>
        <Divider />
        <form
          className={classes.form}
          noValidate
          onSubmit={submitBank}
          autoComplete="off"
        >
          <TextField
            fullWidth
            variant="outlined"
            label="Password"
            className={classes.field}
            value={password}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setPassword(e.target.value)}
            error={passErr ? true : false}
            helperText={passErr ? "Write Password" : ""}
          />
          <TextField
            fullWidth
            variant="outlined"
            multiline
            label="Confirm Password"
            className={classes.field}
            value={rePassword}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setRepassword(e.target.value)}
            error={rePassErr ? true : password === rePassword ? false : true}
            helperText={
              rePassErr
                ? "Please write Repeat password"
                : password === rePassErr
                ? ""
                : "Password confirm must same as password "
            }
          />

          <Button
            fullWidth
            variant="contained"
            className={classes.submit}
            color="secondary"
            type="submit"
          >
            Submit
          </Button>
        </form>

        <Divider />
        <Bottom />
        <BottomNav data={user} />
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={snackBar}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Password Updated
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};
