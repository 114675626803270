import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import RestoreIcon from "@material-ui/icons/Restore";

import HomeIcon from "@material-ui/icons/Home";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import AddIcon from "@material-ui/icons/Add";

import UserContext from "../context/UserContext";

const useStyles = makeStyles({
  root: {
    width: 500,
  },
  bottom: {
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "100%",
  },
  font: {
   fontFamily: "Josefin Sans",
  },
});

export default function BottomNav() {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  const [value, setValue] = useState();

  return (
    <>
      {user.role === "user" && (
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          className={classes.bottom}
        >
          <BottomNavigationAction component={Link} to="/" icon={<HomeIcon />} />
          <BottomNavigationAction icon={<PlayArrowIcon />} />
          <BottomNavigationAction
            component={Link}
            to="/history"
            icon={<RestoreIcon />}
          />
        </BottomNavigation>
      )}
      {user.role === "bookie" && (
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          className={classes.bottom}
        >
          <BottomNavigationAction
            component={Link}
            to="/bookie/index"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            component={Link}
            to="/bookie/adduser"
            icon={<AddIcon />}
          />
          <BottomNavigationAction
            component={Link}
            to="/bookie/withdraw"
            icon={<RemoveRedEyeIcon />}
          />
        </BottomNavigation>
      )}
      {user.role === "admin" && (
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          className={classes.bottom}
        >
          <BottomNavigationAction
            component={Link}
            to="/admin"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            component={Link}
            to="/admin/result"
            icon={<AddIcon />}
          />
          <BottomNavigationAction
            component={Link}
            to="/admin/payments"
            icon={<RemoveRedEyeIcon />}
          />
        </BottomNavigation>
      )}
    </>
  );
}
