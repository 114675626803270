import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useParams, Redirect, Link } from "react-router-dom";
import * as moment from "moment";

import me from './../img/me.jpeg';

import {
  Divider,
  Typography,
  Toolbar,

  Grid,

  Backdrop,
  CircularProgress,
  Box,
} from "@material-ui/core";

import axios from "axios";
import MenuBar from "./wrapper/MenuBar";

import BottomNav from "./wrapper/BottomNav";

import UserContext from "./context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
   fontFamily: "Josefin Sans",
  },

  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
  },
  gameHead:{
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#95d6ed",
    fontWeight: "bold",
    color:'#fff',
    marginLeft:'15px',
    marginRight:'15px'
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  gridClass: {
    textAlign: "center",
    flexDirection: "column",
  },
  addbtn: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
   fontFamily: "Josefin Sans",
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  fontStyle: {
   fontFamily: "Josefin Sans",
  },

  tableContainer: {
    flexDirection: "column",
    marginTop: 50,
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
   fontFamily: "Josefin Sans",
  },
  tableFont: {
   fontFamily: "Josefin Sans",
    color: "#000",
    fontWeight: "bold",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  bottom: {
    marginBottom: 50,
  },
  text: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop:'30px'
  },
  submit: {
   fontFamily: "Josefin Sans",
    backgroundColor: "#00b7fa",

  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  otime:{
paddingLeft:'10px'
  },
  ctime:{
textAlign:"end",
paddingRight:'10px'
  }
}));

export const SelectType = () => {
  const { user, refreshPage } = useContext(UserContext);

  const classes = useStyles();

  let { gid } = useParams();


  const [bidOpenTime, setBidOpenTime] = useState();
  const [bidCloseTime, setBidCloseTime] = useState();


  const [gameTime, setGameTime] = useState([]); //game time response from axios
  const [holiday, setHoliday] = useState(false);
  const [bidTimeOver, setBidTimeOver] = useState(false);

  const [openDisable, setOpenDisable] = useState(false);




  //backdrop
  const [backDrop, setbackDrop] = useState(false);

  var today = new Date();
  const curDay = new Date(today).toLocaleString("en-us", { weekday: "long" });
  const curTime = moment(today.toLocaleTimeString(), "HH:mm A").format("HH:mm");

  useEffect(async () => {
    const game = await axios.get("/admin/games/" + gid);
    const runTimeJson = JSON.parse(game.data.runningTime);
    setGameTime(game.data);


    game.data.status === "running" ? setHoliday(false) : setHoliday(true);
    // console.log(curTime);
    runTimeJson.map((res, i) => {
      if (res.day === curDay) {
        if (res.status === "open") {
          setBidOpenTime(res.bidOpenTime);
          setBidCloseTime(res.bidCloseTime);
          if (curTime >= "00:00" && curTime <= "05:00") {
            return setBidTimeOver(true);
          }
          // console.log(res.bidCloseTime);
          res.bidCloseTime <= curTime
            ? setBidTimeOver(true)
            : setBidTimeOver(false);

          res.bidOpenTime <= curTime
            ? setOpenDisable(true)
            : setOpenDisable(false);
        } else {
          setHoliday(true);
          // console.log("work");
        }
      }
    });
    // console.log(runTimeJson);
  }, []);



  if (user.role === "bookie") {
    return <Redirect to="/bookie/index" />;
  }
  if (user.role === "admin") {
    return <Redirect to="/admin" />;
  }
  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }

  return (
    <div className={classes.root} overflow="hidden">
      <MenuBar data={user} />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            {holiday
              ? "Today Is holiday for " + gameTime.name
              : bidTimeOver
              ? "Bid Time Over for " + gameTime.name
              : gameTime.name}
              
          </Typography>
          
        </Toolbar>
        <Grid container>
        <Grid item xs={6} sm={6} className={classes.otime}>
        Open : {moment(bidOpenTime, "HH:mm:ss").format("hh:mm A")}
        </Grid>
        <Grid item xs={6} sm={6} className={classes.ctime} >
          Close :
        {moment(bidCloseTime, "HH:mm:ss").format("hh:mm A")}
        </Grid>
        </Grid>
       
    

        <Grid container>
            <Grid item xs={12} sm={12}>
            <Typography variant="h6" className={classes.gameHead}>
           Bid on Single
          </Typography>
            <Box component="span" m={1}  
            display="flex"
            justifyContent="center"
            alignItems="center"
            component={Link}
            to={"/otcType/"+gid+"/single"}
          
            >
          <img src={me}/>
            </Box>
            <Divider/>
        </Grid>

        <Grid item xs={12} sm={12}>
        <Typography variant="h6" className={classes.gameHead}>
           Bid on Jodi
          </Typography>
            <Box component="span" m={1}  
            display="flex"
            justifyContent="center"
            alignItems="center"
            component={Link}
            to={
              openDisable ? 
              '' : "/digitForm/"+gid+"/jodi/close"}
           
            >
          <img src={me}/>
            </Box>
            <Divider/>
        </Grid>
        <Grid item xs={12} sm={12}>
        <Typography variant="h6" className={classes.gameHead}>
           Bid on Panna
          </Typography>
          </Grid>
        <Grid item xs={4} sm={4}>
        <Box component="span" m={1}  
            display="flex"
            justifyContent="center"
            alignItems="center"
            component={Link}
            to={"/otcType/"+gid+"/singlePatti"}
            >
          <img src={me}/>
            </Box>
        </Grid>

        <Grid item xs={4} sm={4}>
        <Box component="span" m={1}  
            display="flex"
            justifyContent="center"
            alignItems="center"
            component={Link}
            to={"/otcType/"+gid+"/doublePatti"}
            >
          <img src={me}/>
            </Box>
        </Grid>
        
        <Grid item xs={4} sm={4}>
        <Box component="span" m={1}  
            display="flex"
            justifyContent="center"
            alignItems="center"
            component={Link}
            to={"/otcType/"+gid+"/tripplePatti"}
          
            >
          <img src={me}/>
            </Box>
        </Grid>
        </Grid>
        <Divider />


 

    
        <Toolbar className={classes.bottom}>
          <Typography variant="h6" className={classes.text}>
            Made With love
          </Typography>
        </Toolbar>
        <BottomNav data={user} />
  
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    </div>
  );
};
