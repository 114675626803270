import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  ButtonGroup,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Chip,
  Grid,
} from "@material-ui/core";

import MenuBar from "../wrapper/MenuBar";

import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Alert, Pagination } from "@material-ui/lab";
import { Redirect, Link } from "react-router-dom";
import UserContext from "../context/UserContext";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },

  title: {
    fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
    fontSize: '25px'
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  tableContainer: {
    flexDirection: "column",
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Josefin Sans",
  },
  tableFont: {
    fontFamily: "Josefin Sans",
    color: "#000",
    fontWeight: "bold",
  },
  paginate: {
    margin: theme.spacing(1),
  },
  search: {
    backgroundColor: "#fff",
    fontFamily: "Josefin Sans",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  formControlUp: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  field: {
    fontFamily: "Josefin Sans",
    paddingRight: '10px',
    paddingLeft: '10px',
    marginBottom: '10px',
    marginTop: '10px',
  },
  tableFontTypo: {
    color: '#f44336',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  statusActive: {
    backgroundColor: '#4caf50',
    color: '#fff',
    fontFamily: "Josefin Sans",
    marginBottom: '5px',
  },
  chipInfo: {
    backgroundColor: "#f57c00",
    color: '#fff',
    fontFamily: "Josefin Sans",
    marginBottom: '5px',
  },
  chipRed: {
    backgroundColor: '#f44336',
    color: '#fff',
    fontFamily: "Josefin Sans",
    marginBottom: '5px',
  }
}));

export const WinnerList = () => {
  const { user, refreshPage } = useContext(UserContext);

  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState([]);


  //user id
  const [userId, setUserId] = useState('');

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  //backdrop
  const [backDrop, setBackDrop] = useState(false);

  //dialog
  const [dialogOpen, setDialogOpen] = useState(false);





  const [key, setKey] = useState("");

  const [page, setPage] = useState(1);

  const [deleteAccDialog, setDeleteAccDialog] = useState(false);

  //refresh page
  const [refresh, setRefresh] = useState(false);

  useEffect(async () => {
    const getUsers = await axios.post("getWinnerList?page=" + page, {
      key,
      fromDate: moment(fromDate).tz("Asia/Kolkata").format("YYYY-MM-DD"),
      toDate: moment(toDate).tz("Asia/Kolkata").format("YYYY-MM-DD")
    });
    setUsers(getUsers.data.data);
    setPagination(getUsers.data);

  }, [page, key, refresh, fromDate, toDate]);

  const handleChangePage = async (page) => {
    setPage(page);
    // console.log(page);
  };


  //dialaog close function
  const handleDialogClose = () => {
    setDialogOpen(false);
    setDeleteAccDialog(false);
  };


  const updateWinner = async (userId, histId, type) => {
    setBackDrop(true);
    const res = await axios.post("updateWinner", {
      userId, histId, type
    });

    if (res.data === "success") {
      setRefresh((v) => !v);
      setBackDrop(false);
    }
  }






  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "bookie") {
    return <Redirect to="/bookie/index" />;
  }
  if (user.role === 'moderator') {
    return <Redirect to="/moderator/report" />
  }

  return (
    <div className={classes.root}>
      <MenuBar />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Winner List
          </Typography>



        </Toolbar>
        <Grid container className={classes.digit}>
          <Grid item xs={6} sm={6} className={classes.field}>
            <InputLabel className={classes.label} >From</InputLabel>

            <DateTimePicker
              onChange={setFromDate}
              value={fromDate}
              format="dd-MM-y"
            />
          </Grid>
          <Grid item xs={6} sm={6} className={classes.field}>
            <InputLabel className={classes.label} >To</InputLabel>

            <DateTimePicker
              onChange={setToDate}
              value={toDate}
              format="dd-MM-y"
            />
          </Grid>

        </Grid>
        <Divider />

        <TextField
          className={classes.search}
          value={key}
          onChange={(e) => setKey(e.target.value)}
          label="Search user"
          fullWidth
        />

        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>

                <TableCell align="center" className={classes.tableFont}>
                  Usename
                </TableCell>

                <TableCell align="center" className={classes.tableFont}>
                  Game
                </TableCell>

                <TableCell align="center" className={classes.tableFont}>
                  Played Number
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Played Points
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Balance
                </TableCell>

                <TableCell align="center" className={classes.tableFont}>
                  Action
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                return (
                  <TableRow key={user.id}>

                    <TableCell align="center" className={classes.tableFont}>
                      <Link className={classes.tableFontTypo} to={"/admin/history/" + user.userId}>
                        {user.user_name}
                      </Link>


                    </TableCell>

                    <TableCell align="center" className={classes.tableFont}>
                      {user.gameName} {user.gameType} {user.otc}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {user.result}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {user.points}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {user.userBal}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {user.wonAmt === null ?
                        <><Chip className={classes.statusActive} onClick={() => updateWinner(user.userId, user.histId, 'approve')} label="Approve" /><br />

                          <Chip className={classes.chipRed} onClick={() => updateWinner(user.userId, user.histId, 'reject')} label='Reject' /><br /></> : <Chip className={classes.statusActive} label='Approved' />
                      }

                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          className={classes.paginate}
          page={page}
          count={pagination.last_page}
          component="div"
          color="primary"
          onChange={(e, page) => handleChangePage(page)}
        />
        <Divider />
        <Bottom />
        <BottomNav />

        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {/*snackbar start  */}
        <Snackbar autoHideDuration={10000}>
          <Alert severity="success">Password Updated</Alert>
        </Snackbar>
        {/* Dialog start */}



      </>
    </div>
  );
};
