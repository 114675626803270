import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '@material-ui/core';

export const ExportToExcel = ({apiData,filename}) => {
    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (apiData, fileName) => {
    
        const title = [
            [fileName,'','']
        ]
        const Header1 = [
            ["Single Open","","","","Single Close","","","","Jodi","","","","Single Patti Open","","","","Single Patti Close","","","","Duoble Patti open","","","","Double Patti close","","","","Triple Patti open","","","","Triple Patti close"]
        ];
        const ws = XLSX.utils.json_to_sheet(title,{origin:'D1',skipHeader: true});
        
        XLSX.utils.sheet_add_json(ws,Header1,{origin:'B3',skipHeader: true});
        XLSX.utils.sheet_add_json(ws,apiData.singleOpen,{origin:'A4'});
        XLSX.utils.sheet_add_json(ws,apiData.singleClose,{origin:'E4'});
        XLSX.utils.sheet_add_json(ws,apiData.jodi,{origin:'I4'});
        XLSX.utils.sheet_add_json(ws,apiData.singlePattiOpen,{origin:'M4'});
        XLSX.utils.sheet_add_json(ws,apiData.singlePattiClose,{origin:'Q4'});
        XLSX.utils.sheet_add_json(ws,apiData.doublePattiOpen,{origin:'U4'});
        XLSX.utils.sheet_add_json(ws,apiData.doublePattiClose,{origin:'Y4'});
        XLSX.utils.sheet_add_json(ws,apiData.tripplePattiOpen,{origin:'AC4'});
        XLSX.utils.sheet_add_json(ws,apiData.tripplePattiClose,{origin:'AG4'});
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      };

      return (
        <Button
          variant="contained"

          color="secondary" onClick={(e) => exportToCSV(apiData, filename)}>
              Export
        </Button>
      )
}