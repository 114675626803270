import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  ButtonGroup,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Chip,
} from "@material-ui/core";

import MenuBar from "../wrapper/MenuBar";

import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Alert, Pagination } from "@material-ui/lab";
import { Link, Redirect } from "react-router-dom";
import UserContext from "../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },

  title: {
    fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
    fontSize:'25px'
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  tableContainer: {
    flexDirection: "column",
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Josefin Sans",
  },
  tableFont: {
    fontFamily: "Josefin Sans",
    color: "#000",
    fontSize:'20px',
  },
  paginate: {
    margin: theme.spacing(1),
  },
  search: {
    backgroundColor: "#fff",
    fontFamily: "Josefin Sans",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  formControlUp: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },

  tableFontTypo:{
    color:'#f44336',
    fontWeight:'bold',
    textDecoration:'none',
  },
  tableFontTypoBla:{
    color:'#000',
    fontWeight:'bold',
  },
  statusActive:{
    backgroundColor:'#4caf50',
    color:'#fff',
    fontFamily: "Josefin Sans",
    marginBottom:'5px',
  },
  chipInfo:{
    backgroundColor:"#f57c00",
    color:'#fff',
    fontFamily: "Josefin Sans",
    marginBottom:'5px',
  },
  chipRed:{
    backgroundColor:'#f44336',
    color:'#fff',
    fontFamily: "Josefin Sans",
    marginBottom:'5px',
  }
}));

export const BookieList = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState([]);

  //bookie list
  const [bookies, setBookies] = useState([]);

  //select bookie
  const [selectBookie, setSelectBookie] = useState();
  //user id
  const [userId, setUserId] = useState();

  //backdrop
  const [backDrop, setBackDrop] = useState(false);

  //dialog
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  //bal
  const [addBal, setAddBal] = useState("");
  const [userName, setUserName] = useState();

  //bal err
  const [balErr, setBalErr] = useState(false);

  //select
  const [relevence, setRelevence] = useState("all");

  const [key, setKey] = useState("");

  const [page, setPage] = useState(1);

  //refresh page
  const [refreshPage, setRefresh] = useState(false);


  

  useEffect(async () => {
    const getUsers = await axios.post("bookieList?page=" + page, {
      key,
      relevence,
    });
    setUsers(getUsers.data.data);
    setPagination(getUsers.data);
    // console.log(getUsers.data);
  }, [page, key, refreshPage, relevence]);

  const handleChangePage = async (page) => {
    setPage(page);
    // console.log(page);
  };
  const assignBookie = async (id) => {
    setUserId(id);
    const resBookie = await axios.get("bookieList");
    setBookies(resBookie.data);

    setDialogOpen(true);
  };
  const bookieSubmit = async () => {
    setDialogOpen(false);
    setBackDrop(true);
    const res = await axios.post("assignBookie", {
      userId,
      selectBookie,
    });
    // console.log(res);
    if (res.status === 202) {
      setBackDrop(false);
      return setRefresh((v) => !v);
    }
  };



  //Make user as bookie
  const makeBookie = async (id) => {
    setBackDrop(true);

    const res = await axios.post("makeBookie", {
      id,
    });
    if (res.status === 202) {
      setBackDrop(false);

      return setRefresh((v) => !v);
    }
  };

    //add points funtion
    const addPoints = (id, user_name) => {
      setDialogOpen(true);
      setUserName(user_name);
    };

    const handleDialogClose = () => {
      setDialogOpen(false);
    };
  
    const handleBalSubmit = async () => {
      setBackDrop(true);
      setRefresh(false);
      if (addBal === "") {
        setBackDrop(false);
        return setBalErr(true);
      }
      setDialogOpen(false);
      const balResponse = await axios.post("addBalAdmin", {
        userName,
        addBal,
      });
      // console.log(balResponse);
      if (balResponse.status === 200) {
        setBackDrop(false);
        setRefresh(true);
        setUserName("");
      }
    }; 

      //activate deactivate user
  const userStatus = async (id, type) => {
    setRefresh(false);
    const response = await axios.post("agentStatus", {
      id,
      type,
    });
    if (response.status === 200) {
      setRefresh(true);
    }
  };

  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "bookie") {
    return <Redirect to="/bookie/index" />;
  }
  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }

  return (
    <div className={classes.root}>
      <MenuBar data={user} />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Agents
          </Typography>
          <FormControl className={classes.formControlUp}>
            <Select
              onChange={(e) => setRelevence(e.target.value)}
              value={relevence}
            >
              <MenuItem value="all" selected>
                All
              </MenuItem>
              <MenuItem value="deactive">Blocked</MenuItem>
              <MenuItem value="deleted">Deleted</MenuItem>
            </Select>
          </FormControl>
        </Toolbar>
        <Divider />

        <TextField
          className={classes.search}
          value={key}
          onChange={(e) => setKey(e.target.value)}
          label="Search user"
          fullWidth
        />

        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  User
                </TableCell>
           
                <TableCell align="center" className={classes.tableFont}>
                  Contact 
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Points
                </TableCell>
              
                <TableCell align="center" className={classes.tableFont}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                return (
                  <TableRow key={user.id}>
                    <TableCell align="center" className={classes.tableFont}>
                    Username: <span className={classes.tableFontTypo}>{user.user_name}</span> <br/>
                    Name: <span className={classes.tableFontTypoBla}>{user.name}</span> <br/>
                    Passbook: <Link className={classes.tableFontTypo} to={"/admin/history/" + user.id}>{user.user_name}</Link> <br/>
                 
                    </TableCell>
                  
                    <TableCell align="center" className={classes.tableFont}>
                    <span className={classes.tableFontTypo}>{user.contact_no}</span> <br/>
                      {user.email}

                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {user.points}
                    </TableCell>
                 
                    <TableCell align="center" className={classes.tableFont}>
                      
                    <Chip className={classes.statusActive} label="Add Points" onClick={() => addPoints(user.id,user.user_name)} /><br/>
                   

                   {user.status === "active" ? (
                     <Chip className={classes.statusActive} label={user.status} onClick={() => userStatus(user.id, "deactive")} />
                       ) :  (
                         <Chip className={classes.chipRed} label={user.status} onClick={() => userStatus(user.id, "active")}/>
                      )}
                       <br/>
           
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          className={classes.paginate}
          page={page}
          count={pagination.last_page}
          component="div"
          color="primary"
          onChange={(e, page) => handleChangePage(page)}
        />
        <Divider />
        <Bottom />
        <BottomNav data={user} />

        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {/*snackbar start  */}
        <Snackbar autoHideDuration={10000}>
          <Alert severity="success">Password Updated</Alert>
        </Snackbar>
        {/* Dialog start */}
        <Dialog open={dialogOpen} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Bookie</DialogTitle>
          <DialogContent>
            <DialogContentText>Select Bookie username</DialogContentText>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="bookie">Select Bookie</InputLabel>
              <Select
                defaultValue=""
                id="bookie"
                onChange={(e) => setSelectBookie(e.target.value)}
              >
                {bookies.map((item) => {
                  return <MenuItem value={item.id}>{item.user_name}</MenuItem>;
                })}
              </Select>
              <FormHelperText></FormHelperText>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={bookieSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={dialogOpen} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Add point For <Chip label={userName} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter points.. For deduct use '-'
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Enter Points"
              type="number"
              fullWidth
              value={addBal}
              onChange={(e) => setAddBal(e.target.value)}
              error={balErr ? true : false}
              helperText={balErr ? "Please enter points" : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleBalSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </div>
  );
};
