import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  ButtonGroup,
} from "@material-ui/core";

import MenuBar from "../wrapper/MenuBar";

import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Alert } from "@material-ui/lab";
import { Redirect } from "react-router-dom";
import UserContext from "../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },
  card: {
    textAlign: "center",
    flexDirection: "column",
  },
  playBtn: {
    justifyContent: "center",
    alignItems: "center",
  },
  gameHeader: {
    fontSize: "14",
    fontWeight: "bold",
   fontFamily: "Josefin Sans",
  },
  result: {
   fontFamily: "Josefin Sans",
    fontWeight: "bold",
  },
  btn: {
    backgroundColor: "#00b7fa",
  },
  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
    fontSize:'25px'
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  form: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
  },
  field: {
    // margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  submit: {
   fontFamily: "Josefin Sans",
    backgroundColor: "#00b7fa",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  tableContainer: {
    flexDirection: "column",
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
   fontFamily: "Josefin Sans",
  },
  tableFont: {
   fontFamily: "Josefin Sans",
    color: "#000",
    fontWeight: "bold",
  },
}));

export const Result = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  const [result, setResult] = useState([]);

  //game validation input
  const [gameName, setGameName] = useState("");
  const [playType, selectPlayType] = useState("");
  const [number, setNumber] = useState("");

  //refresh page
  const [refresh, setResfresh] = useState(false);

  //backdrop
  const [backDrop, setbackDrop] = useState(false);

  //snackbar
  const [snackBar, setSnackBar] = useState(false);

  useEffect(async () => {
    const getResults = await axios.get("/admin/result");

    setResult(getResults.data);
    // console.log(getResults.data);
  }, [refresh]);

  //submit form
  const updateResult = async (e) => {
    setbackDrop(true);
    e.preventDefault();
    const response = await axios.post("/admin/result", {
      gameName,
      playType,
      number,
    });
    setbackDrop(false);
    // console.log(response.data);
    setResfresh((v) => !v);
  };

  //reset result
  const resetResult = async () => {
    const res = await axios.get("/admin/resultReset");
    setResfresh((v) => !v);
  };

  //snackbar close function
  const handleClose = () => {
    setSnackBar(false);
  };

  //cancel game
  const cancelGame = async (gameName, type) => {
    const res = await axios.post("/admin/cancelGame", {
      gameName,
      type,
    });
    setResfresh((v) => !v);
    // console.log(res);
  };

  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "bookie") {
    return <Redirect to="/bookie/index" />;
  }
  

  return (
    <div className={classes.root}>
      <MenuBar />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Result update
          </Typography>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={resetResult}
          >
            Reset
          </Button>
        </Toolbar>
        <Divider />
        <form onSubmit={updateResult}>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="select" shrink>
                      Select Game
                    </InputLabel>
                    <Select
                      id="select"
                      onChange={(e) => setGameName(e.target.value)}
                      value={gameName}
                      required
                    >
                      {result.map((item) => {
                        return (
                          <MenuItem value={item.game_name} key={item.id}>
                            {item.game_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="status" shrink>
                      Type
                    </InputLabel>
                    <Select
                      id="status"
                      onChange={(e) => selectPlayType(e.target.value)}
                      value={playType}
                      required
                    >
                      <MenuItem value="open">Open</MenuItem>
                      <MenuItem value="close">Close</MenuItem>
                    </Select>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableBody>
                <TableRow>
                  <TableCell align="center" className={classes.tableFont}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Result"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setNumber(e.target.value)}
                      value={number}
                      required
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            fullWidth
            variant="contained"
            className={classes.submit}
            color="secondary"
            type="submit"
          >
            Update
          </Button>
        </form>

        <Divider />

        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Name
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Open
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Close
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Jodi
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {result.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.game_name}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.open}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.close}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.jodi}
                    </TableCell>
                    <TableCell>
                      <ButtonGroup
                        size="small"
                        aria-label="small outlined button group"
                        color="primary"
                      >
                        <Button
                          onClick={() => cancelGame(item.game_name, "cancel")}
                        >
                          Cancel Game
                        </Button>
                        <Button
                          onClick={() => cancelGame(item.game_name, "wrong")}
                        >
                          Wrong Result
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Bottom />
        <BottomNav />
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={snackBar}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Password Updated
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};
