import React, { useState } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Backdrop,
  CircularProgress,
  Snackbar,
  Fab,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { useParams } from "react-router-dom";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#00b7fa',
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#00b7fa",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  bottom: {
    position: "fixed",
    bottom: "5%",
    right: "10%",
    color: "#fff",
    backgroundColor: "#25D366",
  },

  font:{
    fontFamily: "Josefin Sans",
  }
}));

export default function Signup() {
  const classes = useStyles();

  let { referal } = useParams();

  const [name, setName] = useState("");
  const [user_name, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [contact_no, setContact] = useState("");
  const [bookie_id, setBookie] = useState(referal);
  const [password, setPassword] = useState("");
  const [password_confirm, setPasswordConfirm] = useState("");

  const [user_name_error, setUsernameErr] = useState(false);
  const [email_error, setEmailErr] = useState(false);
  const [contact_no_error, setContactErr] = useState(false);

  const [bookieErr, setBookieErr] = useState(false);

  const [redirect, setRedirect] = useState(false);

  const [backDrop, setbackDrop] = useState(false);

  const [error, setError] = useState(false);

  const [errMsg, setErrMsg] = useState("");

  const checkAvailable = async (val, type) => {
    const response = await axios.post("checkAvailabel", { val });
    if (response.data) {
      return switch_case(type, val);
    }

    function switch_case(type, val) {
      switch (type) {
        case "user_name":
          if (response.data.msg === "exist") {
            return setUsernameErr(true);
          }
          return setUsernameErr(false), setUsername(val);
          break;

        case "email":
          if (response.data.msg === "exist") {
            return setEmailErr(true);
          }
          return setEmailErr(false), setEmail(val);
        case "contact_no":
          if (response.data.msg === "exist") {
            return setContactErr(true);
          }
          return setContactErr(false), setContact(val);
        default:
          break;
      }
    }
  };



  const handleSubmit = async (e) => {
    setbackDrop(true);
    e.preventDefault();

    if (user_name === "" || email === "" || contact_no === "") {
      setErrMsg("All fields are required...");
      setError(true);
      setbackDrop(false);
    } else {
      const response = await axios.post("register", {
        name,
        user_name,
        email,
        contact_no,
        bookie_id,
        password,
        password_confirm,
      });
      setbackDrop(false);

      if (response.data === "exceed") {
        setErrMsg("Bookie user limit exceded..");
        setError(true);
      }
      if (response.data === "wrong") {
        setBookieErr(true);
      }

      // console.log(response);
      if (response.status === 201) {
        setRedirect(true);
      }
    }
  };
  const handleClose = () => {
    setError(false);
  };



  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Backdrop className={classes.backdrop} open={backDrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={error} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errMsg}
        </Alert>
      </Snackbar>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" className={classes.font} variant="h5">
          Sign up
        </Typography>
        <form
          className={classes.form}
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="name"
                variant="outlined"
                required
                fullWidth
                id="name"
                label="Name"
                autoFocus
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="user_name"
                label="Username"
                name="user_name"
                onChange={(e) => checkAvailable(e.target.value, "user_name")}
                helperText={user_name_error ? "Username already exist" : ""}
                error={user_name_error ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Email Address"
                onChange={(e) => checkAvailable(e.target.value, "email")}
                helperText={email_error ? "Email already exist" : ""}
                error={email_error ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Contact Number"
                type="number"
                onChange={(e) => checkAvailable(e.target.value, "contact_no")}
                helperText={
                  contact_no_error ? "Contact Number already exist" : ""
                }
                error={contact_no_error ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              {
                bookie_id === undefined ? '': <TextField
                variant="outlined"
                required
                fullWidth
                label="Bookie Referal Code"
                value={bookie_id}
                onChange={(e) => setBookie(e.target.value)}
                disabled={referal !== undefined ? true : false}
                error={bookieErr ? true : false}
                helperText={bookieErr ? "Bookie referal code is wrong" : ""}
              />
              }
              
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Password Confirm"
                type="password"
                onChange={(e) => setPasswordConfirm(e.target.value)}
                error={password === password_confirm ? false : true}
                helperText={
                  password === password_confirm
                    ? ""
                    : "Password confirm must same as password"
                }
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/login" variant="body2" className={classes.font}>
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}></Box>

    </Container>
  );
}
