import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";

import MenuBar from "./wrapper/MenuBar";

import BottomNav from "./wrapper/BottomNav";

import axios from "axios";
import Bottom from "./wrapper/Bottom";
import { Alert } from "@material-ui/lab";

import * as moment from "moment";

import UserContext from "./context/UserContext";
import { Redirect } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },

  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  form: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
  },
  field: {
    // margin: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submit: {
   fontFamily: "Josefin Sans",
    backgroundColor: "#00b7fa",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  tableFont: {
   fontFamily: "Josefin Sans",
    color: "#000",
    fontWeight: "bold",
  },
}));

export const Withdraw = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  const [withdraw, setWithdraw] = useState([]);
  const [refresh, setRefresh] = useState(false);

  //user account details
  const [amt, setAmt] = useState("");

  //err
  const [amtErr, setAmtErr] = useState(false);

  //backdrop
  const [backDrop, setbackDrop] = useState(false);

  //snackbar
  const [snackBar, setSnackBar] = useState(false);

  useEffect(async () => {
    const getWithdraw = await axios.get("withdraw");

    setWithdraw(getWithdraw.data);
    // console.log(getUser.data);
  }, [refresh]);

  const submitBank = async (e) => {
    setbackDrop(true);
    e.preventDefault();
    // console.log(amt);
    if (amt === "") {
      setbackDrop(false);
      return setAmtErr(true);
    }
    if (amt < 600) {
      setbackDrop(false);
      return setAmtErr(true);
    }

    const res = await axios.post("withdraw", {
      amt,
    });
    if (res.status === 201) {
      setbackDrop(false);
      setSnackBar(true);
      setRefresh((v) => !v);
    }
    if (res.status === 200) {
      setbackDrop(false);
      setAmtErr(true);
    }
    // console.log(res);
  };

  const cancelRequest = async (id) => {
    setbackDrop(true);
    const res = await axios.post("/cancelWithdraw", {
      id,
    });
    if (res.status === 200) {
      setbackDrop(false);
      setRefresh((v) => !v);
    }
  };
  //snackbar close function
  const handleClose = () => {
    setSnackBar(false);
  };

  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }

  return (
    <div className={classes.root}>
      <MenuBar data={user} />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Withdraw Request
          </Typography>
        </Toolbar>
        <Divider />
        <form
          className={classes.form}
          noValidate
          onSubmit={submitBank}
          autoComplete="off"
        >
          <TextField
            fullWidth
            variant="outlined"
            label="Enter Amount"
            type="number"
            className={classes.field}
            value={amt}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setAmt(e.target.value)}
            error={amtErr ? true : false}
            helperText={amtErr ? "Min amount 600 rs" : ""}
          />

          <Button
            fullWidth
            variant="contained"
            className={classes.submit}
            color="secondary"
            type="submit"
          >
            Submit
          </Button>
        </form>

        <Divider />
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Recent withdraw requests
          </Typography>
        </Toolbar>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Amount Requested
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Reference
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Remarks
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Status
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Action
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Time
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {withdraw.map((item) => {
                return (
                  <TableRow>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.amount}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.reference}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.remarks}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.status}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.status === "Requested" ? (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => cancelRequest(item.id)}
                        >
                          Cancel
                        </Button>
                      ) : item.status === "Cancelled" ? (
                        <Button size="small" variant="contained" disabled>
                          Cancelled
                        </Button>
                      ) : item.status === "Approved" ? (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          disabled
                        >
                          Approved
                        </Button>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {moment(item.created_at).format("D-M-YYYY hh:mm A")}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Bottom />
        <BottomNav data={user} />
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={snackBar}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Withdraw request added !
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};
