import React, { useEffect, useState, useContext } from "react";
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemIcon,
  Divider,
  Drawer,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Chip,
  TextField,
  DialogActions,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import EjectIcon from "@material-ui/icons/Eject";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";
import ShareIcon from "@material-ui/icons/Share";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Link, Redirect } from "react-router-dom";
import RestoreIcon from "@material-ui/icons/Restore";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import ListIcon from "@material-ui/icons/List";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import UserContext from "../context/UserContext";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  orange: {
    background: "linear-gradient(45deg, #00b7fa 30%, #78dbff 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(121, 218, 252, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
    left: "30%",
  },
  back: {
    backgroundColor: "#00b7fa",
  },
  AppHead: {
    fontFamily: "Josefin Sans",
    fontWeight: "bold",
  },
  float: {
    position: "fixed",
    bottom: 90,
    right: 20,
  },

  profileIco: {
    position: "absolute",
    right: 20,
  },
  listFont: {
    fontFamily: "Josefin Sans",

  },
  points: {
    fontFamily: "Josefin Sans",
    fontWeight: "bold",
    fontSize: 12,
  },
}));

const theme = createMuiTheme({
  Typography: {
    fontFamily: "Josefin Sans",
  },
});

export default function MenuBar() {
  const { user, bookie, refreshPage } = useContext(UserContext);

  const classes = useStyles();

  const [redirect, setRedirect] = useState(false);

  //dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  //bal
  const [addBal, setAddBal] = useState("");


  const [userName, setUserName] = useState();

  //bal err
  const [balErr, setBalErr] = useState(false);

  useEffect(() => {
    const getAuth = localStorage.getItem("token");
    if (getAuth === null) {
      setRedirect(true);
    }
  }, []);

  const addBalAdmin = (user_name) => {

    setDialogOpen(true);
    setUserName(user_name);
    console.log(user_name);
  }
  const handleBalSubmit = async () => {


    if (addBal === "") {

      return setBalErr(true);
    }
    setDialogOpen(false);
    const balResponse = await axios.post("addBalAdmin", {
      userName,
      addBal,
    });
    console.log(balResponse);
    if (balResponse.status === 200) {

      refreshPage();
      setUserName("");
    }
  };

  //dialaog close function
  const handleDialogClose = () => {
    setDialogOpen(false);
  };



  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const anchor = "left";

  const handleLogOut = () => {
    // console.log("clicked");
    localStorage.clear();
    setRedirect(true);
  };
  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="sticky" className={classes.back}>
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon onClick={toggleDrawer("left", true)} />
          </IconButton>

          <Drawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            <div
              className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom",
              })}
              role="presentation"
              onKeyDown={toggleDrawer(anchor, false)}
            >
              {user.role === "user" && (
                <List className={classes.listFont}>
                  <ListItem button key={"text"}>
                    <Avatar
                      alt="Remy Sharp"
                      src="/broken-image.jpg"
                      className={classes.orange}
                    >
                      <AccountCircleIcon />
                    </Avatar>
                  </ListItem>
                  <ListItem button key="home" component={Link} to="/">
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>Home</Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem button key="history" component={Link} to="/history">
                    <ListItemIcon>
                      <RestoreIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        History
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem button key="profile" component={Link} to="/profile">
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Profile
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem button key="bank" component={Link} to="/editbank">
                    <ListItemIcon>
                      <AccountBalanceIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Edit Bank Details
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem button key="pass" component={Link} to="/password">
                    <ListItemIcon>
                      <LockOpenIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Change Password
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    key="withdraw"
                    component={Link}
                    to="/withdraw"
                  >
                    <ListItemIcon>
                      <EjectIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Withdraw
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              )}

              {user.role === "bookie" && (
                <List className={classes.listFont}>
                  <ListItem button key={"text"}>
                    <Avatar
                      alt="Remy Sharp"
                      src="/broken-image.jpg"
                      className={classes.orange}
                    >
                      <AccountCircleIcon />
                    </Avatar>
                  </ListItem>
                  <ListItem
                    button
                    key="dash"
                    component={Link}
                    to="/bookie/index"
                  >
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Bookie DashBoard
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem
                    button
                    key="set"
                    component={Link}
                    to="/bookie/selectGame"
                  >
                    <ListItemIcon>
                      <AttachMoneyIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Play Game
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem
                    button
                    key="set"
                    component={Link}
                    to="/bookie/tokenList"
                  >
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Token List
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    key="set"
                    component={Link}
                    to="/bookie/report"
                  >
                    <ListItemIcon>
                      <AttachMoneyIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Generate Report
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    key="res"
                    component={Link}
                    to="/bookie/results"
                  >
                    <ListItemIcon>
                      <DoneIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Results
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    key="adduser"
                    component={Link}
                    to="/bookie/adduser"
                  >
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Add user
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    key="myreferal"
                    component={Link}
                    to="/bookie/myreferal"
                  >
                    <ListItemIcon>
                      <ShareIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        My Referals
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    key="notification"
                    component={Link}
                    to="/bookie/notification"
                  >
                    <ListItemIcon>
                      <NotificationsNoneIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Set Notification
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    key="withdraw"
                    component={Link}
                    to="/bookie/withdraw"
                  >
                    <ListItemIcon>
                      <RemoveRedEyeIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Withdraw list
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem button key="profile" component={Link} to="/profile">
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Profile
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem button key="pass" component={Link} to="/password">
                    <ListItemIcon>
                      <LockOpenIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Change Password
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              )}

              {user.role === "admin" && (
                <List className={classes.listFont}>
                  <ListItem button key={"text"}>
                    <Avatar
                      alt="Remy Sharp"
                      src="/broken-image.jpg"
                      className={classes.orange}
                    >
                      <AccountCircleIcon />
                    </Avatar>
                  </ListItem>
                  <ListItem button key="dash" component={Link} to="/admin">
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Admin DashBoard
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem button key="dash" component={Link} to="/admin/userList">
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        User List
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    key="set"
                    component={Link}
                    to="/admin/bookieList"
                  >
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Agent List
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem
                    button
                    key="set"
                    component={Link}
                    to="/admin/winnerList"
                  >
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Winner List
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem
                    button
                    key="set"
                    component={Link}
                    to="/admin/withdraw"
                  >
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Withdraw List
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem
                    button
                    key="set"
                    component={Link}
                    to="/admin/BookieWithdrawList"
                  >
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Agent Withdraw List
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem
                    button
                    key="adduser"
                    component={Link}
                    to="/admin/adminReport"
                  >
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Report
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    key="adduser"
                    component={Link}
                    to="/admin/addgames"
                  >
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Add Games
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem
                    button
                    key="notification"
                    component={Link}
                    to="/admin/notification"
                  >
                    <ListItemIcon>
                      <NotificationsNoneIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Set Notification
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem
                    button
                    key="adduser"
                    component={Link}
                    to="/admin/addRates"
                  >
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Add Rates
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    key="myreferal"
                    component={Link}
                    to="/admin/result"
                  >
                    <ListItemIcon>
                      <ShareIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Result
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem button key="profile" component={Link} to="/profile">
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Profile
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem button key="pass" component={Link} to="/password">
                    <ListItemIcon>
                      <LockOpenIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Change Password
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              )}


              {user.role === "moderator" && (
                <List className={classes.listFont}>
                  <ListItem button key={"text"}>
                    <Avatar
                      alt="Remy Sharp"
                      src="/broken-image.jpg"
                      className={classes.orange}
                    >
                      <AccountCircleIcon />
                    </Avatar>
                  </ListItem>

                  <ListItem
                    button
                    key="adduser"
                    component={Link}
                    to="/moderator/report"
                  >
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Report
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem
                    button
                    key="myreferal"
                    component={Link}
                    to="/moderator/result"
                  >
                    <ListItemIcon>
                      <ShareIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Result
                      </Typography>
                    </ListItemText>
                  </ListItem>

                  <ListItem button key="profile" component={Link} to="/profile">
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Profile
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem button key="pass" component={Link} to="/password">
                    <ListItemIcon>
                      <LockOpenIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listFont}>
                        Change Password
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              )}

              <ListItem
                button
                key="notification"
                component={Button}
                onClick={handleLogOut}
              >
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography className={classes.listFont}>Log Out</Typography>
                </ListItemText>
              </ListItem>
              <Divider />
            </div>
          </Drawer>
          <Typography variant="h6" className={classes.AppHead} color="inherit">
            Vipgames
          </Typography>

          {user.role === "user" && (
            <div className={classes.profileIco}>
              {
                user.bookie_id === '1' ? '' : <Typography
                  variant="h6"
                  className={classes.points}
                  color="inherit"
                >
                  Agent: {bookie.user_name}
                </Typography>
              }

              <Typography
                variant="h6"
                className={classes.points}
                color="inherit"
              >
                Points : {user.points}
              </Typography>
            </div>
          )}

          {user.role === "bookie" && (
            <div className={classes.profileIco}>

              <Typography
                variant="h6"
                className={classes.points}
                color="inherit"
              >
                Points : {user.points}
              </Typography>
            </div>
          )}

          {user.role === "admin" && (
            <div className={classes.profileIco}>

              <Typography
                variant="h6"
                className={classes.points}
                color="inherit"
                onClick={() => addBalAdmin(user.user_name)}
              >
                Points : {user.points}
              </Typography>
            </div>
          )}
        </Toolbar>
      </AppBar>

      <Dialog open={dialogOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Add point For <Chip label={userName} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter points.. For deduct use '-'
            </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Enter Points"
            type="number"
            fullWidth
            value={addBal}
            onChange={(e) => setAddBal(e.target.value)}
            error={balErr ? true : false}
            helperText={balErr ? "Please enter points" : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
            </Button>
          <Button onClick={handleBalSubmit} color="primary">
            Submit
            </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
