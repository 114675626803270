import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Card,
  CardContent,
  Typography,
  Toolbar,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
} from "@material-ui/core";

import MenuBar from "../wrapper/MenuBar";

import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Redirect } from "react-router-dom";
import UserContext from "../context/UserContext";
import DateTimePicker from 'react-datetime-picker';
import Autocomplete from "@material-ui/lab/Autocomplete";

import moment from "moment-timezone";

import ReactToPdf from 'react-to-pdf';
import { ExportToExcel } from "./ExportToExcel";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },
  title: {
    fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
    marginTop:'10px',
    fontSize:'25px'
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  form: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
  },

  submit: {
    fontFamily: "Josefin Sans",
    backgroundColor: "#00b7fa",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  digit:{
    paddingTop:'10px',
    backgroundColor: "#fff",
},
field:{
    paddingRight:'10px',
    paddingLeft:'10px',
    marginBottom:'10px',
          marginTop:'10px',
},
formControl: {
    margin: theme.spacing(1),
    minWidth: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    
    // alignItems: "center",
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  fontStyle: {
    fontFamily: "Josefin Sans",
  },

  tableContainer: {
    flexDirection: "column",

  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Josefin Sans",
  },
  tableFont: {
    fontFamily: "Josefin Sans",
    color: "#000",
    fontWeight: "bold",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  bottom: {
    marginBottom: 50,
  },
  text: {
    fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  submit: {
    fontFamily: "Josefin Sans",
    backgroundColor: "#00b7fa",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  grid:{
    marginTop:'10px',
    textAlign:'center'
  }
}));

export const AdminReport = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  const [gameName,setGameName] = useState('');
  const [gameNameErr,setGameNameErr] = useState(false);

const [games,setGames] = useState([]);

const [fromDate,setFromDate] = useState(new Date());
const [toDate,setToDate] = useState(new Date());

const [userName,setUserName] = useState('');
const [key,setKey] = useState('');
const [userList,setUserList] = useState([]);

const [singleOpen,setSingleOpen] = useState([]);
const [singleClose,setSingleClose] = useState([]);
const [jodi,setJodi] = useState([]);
const [singlePattiOpen,setSinglePattiOpen] = useState([]);
const [singlePattiClose, setSinglePattiClose] =useState([]);
const [doublePattiOpen,setDoublePattiOpen] = useState([]);
const [doublePattiClose,setDoublePattiClose] = useState([]);
const [tripplePattiOpen,setTripplePattiOpen] = useState([]);
const [tripplePattiClose,setTripplePattiClose] = useState([]);

const [data,setData] =useState([]);

const ref = React.createRef();

const options = {
  unit: 'in',
  format: [30,20]
};

moment.tz.setDefault('Asia/Kolkata');
  useEffect(async () => {
    const getGames = await axios.get("admin/games");

    setGames(getGames.data);
  }, []);

  useEffect(async () => {
    const getUser = await axios.post("/admin/searchUser", {
      key,
    });
    setUserList(getUser.data);


    console.log(getUser.data);
  }, [key]);

  const submit = async() =>{
    if(gameName === ''){
      return setGameNameErr(true);
    }
    setGameNameErr(false);
    const getReport = await axios.post("/admin/generateReport", {
        userName,
        gameName,
        fromDate:moment(fromDate).tz("Asia/Kolkata").format("YYYY-MM-DD"),
        toDate:moment(toDate).tz("Asia/Kolkata").format("YYYY-MM-DD"),
      });

      setData(getReport.data);

      setSingleOpen(getReport.data.singleOpen);
      setSingleClose(getReport.data.singleClose);
      setJodi(getReport.data.jodi);
      setSinglePattiOpen(getReport.data.singlePattiOpen);
      setSinglePattiClose(getReport.data.singlePattiClose);
      setDoublePattiOpen(getReport.data.doublePattiOpen);
      setDoublePattiClose(getReport.data.doublePattiClose);
      setTripplePattiOpen(getReport.data.tripplePattiOpen);
      setTripplePattiClose(getReport.data.tripplePattiClose);
      console.log(getReport);
  }

  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "bookie") {
    return <Redirect to="/bookie/index" />;
  }
  return (
    <div className={classes.root}>
      <MenuBar />

      <Toolbar className={classes.subHead}>
        <Typography variant="h6" className={classes.title}>
          Reports
        </Typography>
      </Toolbar>
      <Divider />

      <Grid container className={classes.digit}>
              <Grid item xs={6} sm={3} className={classes.field}>
              <InputLabel className={classes.label} >From</InputLabel>
        
          <DateTimePicker
        onChange={setFromDate} 
        value={fromDate}
        format="dd-MM-y"
      />
                  </Grid>
                  <Grid item xs={6} sm={3} className={classes.field}>
                  <InputLabel className={classes.label} >To</InputLabel>
               
          <DateTimePicker
        onChange={setToDate} 
        value={toDate}
        format="dd-MM-y"
      />
                  </Grid>
                  <Grid item xs={6} sm={3} className={classes.field}>
                  <FormControl className={classes.formControl} error={gameNameErr?true:false}>
                    <InputLabel htmlFor="select" shrink>
                      Select Game
                    </InputLabel>
                    <Select
                      id="select"
                      onChange={(e) => setGameName(e.target.value)}
                      value={gameName}
                      required
                    >
                      {games.map((item) => {
                        return (
                          <MenuItem value={item.name} key={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {
                        gameNameErr?'Please select gamename':''
                      }
                    </FormHelperText>
                  </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3} className={classes.field}>
              <InputLabel className={classes.label} >UserName</InputLabel>



            <Autocomplete
              id="controllable-states-demo"
              // options={userList.map((option) => option.name)}
              options={userList}
              getOptionLabel={(option) => option.name}
              renderOption={(option)=>option.name}
              onChange={(e, fVal) => {
                setUserName(fVal);
              }}
              value={userName}
              onInputChange={(e, val) => {
                setKey(val);
              }}
              inputValue={key}
              renderInput={(params) => (
                <TextField
                  {...params}
                 
                  variant="outlined"
                />
              )}
            />
                  </Grid>
                  </Grid>
        <Button
          variant="contained"
          className={classes.submit}
          color="secondary"
          fullWidth={true}   
          onClick={submit}      
        >
          Get Report
        </Button>

      <Divider />
      <br/>
      <ReactToPdf targetRef={ref} x={.5} y={.5} scale={0.8} options={options}  filename={gameName+fromDate+toDate+".pdf"}>
        {({toPdf}) => (
            <button onClick={toPdf} >Generate pdf</button>
        )}
    </ReactToPdf>

    <Button variant="contained" color="primary"  onClick={() => window.print()}>PRINT</Button>

    <ExportToExcel apiData={data} filename={gameName+fromDate+toDate} />
{/* single open start */}
<div ref={ref}>
<Grid container className={classes.grid}>
  <Grid item xs={4}>From: {moment(fromDate).tz("Asia/Kolkata").format("YYYY-MM-DD")}</Grid>
  <Grid item xs={4}>{gameName}</Grid>
  <Grid item xs={4}>To: {moment(toDate).tz("Asia/Kolkata").format("YYYY-MM-DD")}</Grid>
</Grid>
<Grid container>
<Grid item xs={12} sm={6}>
      <Typography variant="h6" className={classes.title}>
          Single Open
        </Typography>
      <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Number
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Played Amount
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Won Amount
                </TableCell>
             
              </TableRow>
            </TableHead>
            <TableBody>
        
                    

                      {
                        singleOpen.map((item) =>{
                         return <TableRow>
                          <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        className={classes.tableFont}
                      >
                     {item.played_no}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                      {item.amount}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                        {item.wonAmt}
                      </TableCell>
                     
                    </TableRow>
                        })
                      }
                      


              <TableRow>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  className={classes.tableFont}
                > Total</TableCell>
                <TableCell align="center" className={classes.tableFont}>
                 {
                  singleOpen.reduce((a,v) =>  a = parseInt(a) + parseInt(v.amount) , 0 )
                 }
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                {
                  singleOpen.reduce((a,v) =>  a = parseInt(a) + parseInt(v.wonAmt) , 0 )
                 }
                </TableCell>
            
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
{/* single open end */}
</Grid>
{/* single close start */}
<Grid item xs={12} sm={6}>
<Typography variant="h6" className={classes.title}>
          Single Close
        </Typography>
      <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Number
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Played Amount
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Won Amount
                </TableCell>
             
              </TableRow>
            </TableHead>
            <TableBody>
        
                    

                      {
                        singleClose.map((item) =>{
                         return <TableRow>
                          <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        className={classes.tableFont}
                      >
                     {item.played_no}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                      {item.amount}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                        {item.wonAmt}
                      </TableCell>
                     
                    </TableRow>
                        })
                      }
                      


              <TableRow>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  className={classes.tableFont}
                > Total</TableCell>
               <TableCell align="center" className={classes.tableFont}>
                 {
                  singleClose.reduce((a,v) =>  a = parseInt(a) + parseInt(v.amount) , 0 )
                 }
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                {
                  singleClose.reduce((a,v) =>  a = parseInt(a) + parseInt(v.wonAmt) , 0 )
                 }
                </TableCell>
            
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* single close end */}
</Grid>
{/* jodi start */}
<Grid item xs={12} sm={12}>
<Typography variant="h6" className={classes.title}>
          Jodi
        </Typography>
      <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Number
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Played Amount
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Won Amount
                </TableCell>
             
              </TableRow>
            </TableHead>
            <TableBody>
        
                    

                      {
                        jodi.map((item) =>{
                         return <TableRow>
                          <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        className={classes.tableFont}
                      >
                     {item.played_no}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                      {item.amount}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                        {item.wonAmt}
                      </TableCell>
                     
                    </TableRow>
                        })
                      }
                      


              <TableRow>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  className={classes.tableFont}
                > Total</TableCell>
               <TableCell align="center" className={classes.tableFont}>
                 {
                  jodi.reduce((a,v) =>  a = parseInt(a) + parseInt(v.amount) , 0 )
                 }
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                {
                  jodi.reduce((a,v) =>  a = parseInt(a) + parseInt(v.wonAmt) , 0 )
                 }
                </TableCell>
            
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

{/* jodi end */}
</Grid>
{/* singlePatti open start */}
<Grid item xs={12} sm={6}>
<Typography variant="h6" className={classes.title}>
          Single Patti Open
        </Typography>
      <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Number
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Played Amount
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Won Amount
                </TableCell>
             
              </TableRow>
            </TableHead>
            <TableBody>
        
                    

                      {
                        singlePattiOpen.map((item) =>{
                         return <TableRow>
                          <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        className={classes.tableFont}
                      >
                     {item.played_no}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                      {item.amount}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                        {item.wonAmt}
                      </TableCell>
                     
                    </TableRow>
                        })
                      }
                      


              <TableRow>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  className={classes.tableFont}
                > Total</TableCell>
               <TableCell align="center" className={classes.tableFont}>
                 {
                  singlePattiOpen.reduce((a,v) =>  a = parseInt(a) + parseInt(v.amount) , 0 )
                 }
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                {
                  singlePattiClose.reduce((a,v) =>  a = parseInt(a) + parseInt(v.wonAmt) , 0 )
                 }
                </TableCell>
            
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
</Grid>
        {/* singlepatti open end */}

        {/* singlepatti close start */}

  <Grid item xs={12} sm={6}>
<Typography variant="h6" className={classes.title}>
          Single Patti Close
        </Typography>
      <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Number
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Played Amount
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Won Amount
                </TableCell>
             
              </TableRow>
            </TableHead>
            <TableBody>
        
                    

                      {
                        singlePattiClose.map((item) =>{
                         return <TableRow>
                          <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        className={classes.tableFont}
                      >
                     {item.played_no}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                      {item.amount}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                        {item.wonAmt}
                      </TableCell>
                     
                    </TableRow>
                        })
                      }
                      


              <TableRow>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  className={classes.tableFont}
                > Total</TableCell>
               <TableCell align="center" className={classes.tableFont}>
                 {
                  singlePattiClose.reduce((a,v) =>  a = parseInt(a) + parseInt(v.amount) , 0 )
                 }
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                {
                  singlePattiClose.reduce((a,v) =>  a = parseInt(a) + parseInt(v.wonAmt) , 0 )
                 }
                </TableCell>
            
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* singlepatti close end */}
</Grid>
{/* Double patti open start */}
<Grid item xs={12} sm={6}>
<Typography variant="h6" className={classes.title}>
          Double Patti Open
        </Typography>
      <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Number
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Played Amount
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Won Amount
                </TableCell>
             
              </TableRow>
            </TableHead>
            <TableBody>
        
                    

                      {
                        doublePattiOpen.map((item) =>{
                         return <TableRow>
                          <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        className={classes.tableFont}
                      >
                     {item.played_no}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                      {item.amount}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                        {item.wonAmt}
                      </TableCell>
                     
                    </TableRow>
                        })
                      }
                      


              <TableRow>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  className={classes.tableFont}
                > Total</TableCell>
               <TableCell align="center" className={classes.tableFont}>
                 {
                  doublePattiOpen.reduce((a,v) =>  a = parseInt(a) + parseInt(v.amount) , 0 )
                 }
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                {
                  doublePattiOpen.reduce((a,v) =>  a = parseInt(a) + parseInt(v.wonAmt) , 0 )
                 }
                </TableCell>
            
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
</Grid>
        {/* double patti open end */}

        {/* double patti close start */}

        <Grid item xs={12} sm={6}>
<Typography variant="h6" className={classes.title}>
          Double Patti Close
        </Typography>
      <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Number
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Played Amount
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Won Amount
                </TableCell>
             
              </TableRow>
            </TableHead>
            <TableBody>
        {
                      doublePattiClose.map((item) =>{
                         return <TableRow>
                          <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        className={classes.tableFont}
                      >
                     {item.played_no}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                      {item.amount}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                        {item.wonAmt}
                      </TableCell>
                     
                    </TableRow>
                        })
                      }
                      


              <TableRow>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  className={classes.tableFont}
                > Total</TableCell>
               <TableCell align="center" className={classes.tableFont}>
                 {
                  doublePattiClose.reduce((a,v) =>  a = parseInt(a) + parseInt(v.amount) , 0 )
                 }
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                {
                  doublePattiClose.reduce((a,v) =>  a = parseInt(a) + parseInt(v.wonAmt) , 0 )
                 }
                </TableCell>
            
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
</Grid>
        {/* double patti close end */}

        {/* Tripple patti open start */}
        <Grid item xs={12} sm={6}>

        <Typography variant="h6" className={classes.title}>
          Tripple Patti Open
        </Typography>
      <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Number
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Played Amount
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Won Amount
                </TableCell>
             
              </TableRow>
            </TableHead>
            <TableBody>
        
                    

                      {
                        tripplePattiOpen.map((item) =>{
                         return <TableRow>
                          <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        className={classes.tableFont}
                      >
                     {item.played_no}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                      {item.amount}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                        {item.wonAmt}
                      </TableCell>
                     
                    </TableRow>
                        })
                      }
                      


              <TableRow>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  className={classes.tableFont}
                > Total</TableCell>
               <TableCell align="center" className={classes.tableFont}>
                 {
                  tripplePattiOpen.reduce((a,v) =>  a = parseInt(a) + parseInt(v.amount) , 0 )
                 }
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                {
                  tripplePattiOpen.reduce((a,v) =>  a = parseInt(a) + parseInt(v.wonAmt) , 0 )
                 }
                </TableCell>
            
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
</Grid>
        {/* Tripple patti open end */}

        {/* Tripple patti close start */}
        <Grid item xs={12} sm={6}>
<Typography variant="h6" className={classes.title}>
          Tripple Patti Close
        </Typography>
      <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Number
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Played Amount
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Won Amount
                </TableCell>
             
              </TableRow>
            </TableHead>
            <TableBody>
        
                    

                      {
                        tripplePattiClose.map((item) =>{
                         return <TableRow>
                          <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        className={classes.tableFont}
                      >
                     {item.played_no}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                      {item.amount}
                      </TableCell>
                      <TableCell align="center" className={classes.tableFont}>
                        {item.wonAmt}
                      </TableCell>
                     
                    </TableRow>
                        })
                      }
                      


              <TableRow>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  className={classes.tableFont}
                > Total</TableCell>
               <TableCell align="center" className={classes.tableFont}>
                 {
                  tripplePattiClose.reduce((a,v) =>  a = parseInt(a) + parseInt(v.amount) , 0 )
                 }
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                {
                  tripplePattiClose.reduce((a,v) =>  a = parseInt(a) + parseInt(v.wonAmt) , 0 )
                 }
                </TableCell>
            
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        </Grid>
        </Grid>
</div>

      <Bottom />
      <BottomNav />
    </div>
  );
};
