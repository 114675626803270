import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState([]);
  const [bookie, setBookie] = useState([]);

  const [gameTime, setGameTime] = useState([]);

  const [rate, setRate] = useState([]);

  const [notify,setNotify] = useState('');

  //refresh
  const [refresh, setRefresh] = useState(false);

  const refreshPage = () => {
    setRefresh((v) => !v);
  };

  useEffect(() => {
    async function fetchData() {
      const userRes = await axios("user");
      const bookieRes = await axios("getBookie");
      const games = await axios.get("/admin/games");

      const resRate = await axios.get("getBookieRates");

      const getNotify = await axios.get('/notification/1');

      setUser(userRes.data);
      setBookie(bookieRes.data);
      setGameTime(games.data);
      setRate(resRate.data);
      setNotify(getNotify.data);
      console.log(getNotify.data);
    }
    fetchData();
  }, [refresh]);

  return (
    <UserContext.Provider value={{ user, bookie, gameTime, rate, notify, refreshPage }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
