import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  Chip,
  DialogActions,
} from "@material-ui/core";

import MenuBar from "../wrapper/MenuBar";
import { Link, Redirect } from "react-router-dom";
import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Alert } from "@material-ui/lab";
import UserContext from "../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },

  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
  },
  subHead: {
    backgroundColor: "#fafafa",
  },

  tableContainer: {
    flexDirection: "column",
  },
  table: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
   fontFamily: "Josefin Sans",
  },
  tableFont: {
   fontFamily: "Josefin Sans",
    color: "#000",
    fontWeight: "bold",
  },
  paginate: {
    margin: theme.spacing(1),
  },
}));

export const WithdrawList = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  const [withdrawLists, setWithdrawList] = useState([]);

  //refresh page
  const [refresh, setResfresh] = useState(false);

  //backdrop
  const [backDrop, setbackDrop] = useState(false);

  //snackbar
  const [snackBar, setSnackBar] = useState(false);

  //dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  const [userName, setUserName] = useState("");
  const [id, setId] = useState("");

  //reference value
  const [reference, setReference] = useState("");

  //info dialog
  const [infoDialog, setInfoDialog] = useState(false);
  // user_name, email, bank_name, acc_no, ifsc, upi
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [bank, setBank] = useState("");
  const [accNo, setAccNo] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [upi, setUpi] = useState("");

  useEffect(async () => {
    const getWithdraw = await axios.get("withdrawList");

    setWithdrawList(getWithdraw.data);
    console.log(getWithdraw.data);
  }, [refresh]);

  //bookie approve or cancel withdraw request

  const updateRequest = async (id, type) => {
    //type= approve or cancel
    const res = await axios.put("withdraw/" + id, {
      type,
      reference,
    });
    setDialogOpen(false);
    setResfresh((v) => !v);
    // console.log(res);
  };

  const openDialog = (id, user_name) => {
    setDialogOpen(true);
    setUserName(user_name);
    setId(id);
  };

  //snackbar close function
  const handleClose = () => {
    setSnackBar(false);
    setDialogOpen(false);
    setInfoDialog(false);
  };

  //viewInfo dialog
  const viewInfo = (name, user_name, email, bank_name, acc_no, ifsc, upi) => {
    setName(name);
    setUserName(user_name);
    setEmail(email);
    setBank(bank_name);
    setAccNo(acc_no);
    setIfsc(ifsc);
    setUpi(upi);
    setInfoDialog(true);
  };

  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "admin") {
    return <Redirect to="/admin" />;
  }
  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }
  return (
    <div className={classes.root}>
      <MenuBar data={user} />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            Withdraw List
          </Typography>
        </Toolbar>
        <Divider />

        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableFont}>
                  Username
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Contact No
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Withdraw amount
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Balance
                </TableCell>
                <TableCell align="center" className={classes.tableFont}>
                  Info
                </TableCell>

                <TableCell align="center" className={classes.tableFont}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {withdrawLists.map((item) => {
                return (
                  <TableRow>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.user_name}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.contact_no}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.amount}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {item.points}
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      <ButtonGroup
                        size="small"
                        aria-label="small outlined button group"
                      >
                        <Button
                          onClick={() =>
                            viewInfo(
                              item.name,
                              item.user_name,
                              item.email,
                              item.bank_name,
                              item.acc_no,
                              item.ifsc,
                              item.upi
                            )
                          }
                        >
                          View Info
                        </Button>
                        <Button
                          component={Link}
                          to={"/bookie/history/" + item.userId}
                        >
                          History
                        </Button>
                      </ButtonGroup>
                    </TableCell>

                    <TableCell align="center" className={classes.tableFont}>
                      {item.wstatus === "Cancelled" ? (
                        <Button color="primary" variant="outlined" disabled>
                          Cancelled
                        </Button>
                      ) : item.wstatus === "Approved" ? (
                        <Button color="primary" variant="outlined" disabled>
                          Approved
                        </Button>
                      ) : item.wstatus === "Requested" ? (
                        <ButtonGroup
                          size="small"
                          aria-label="small outlined button group"
                        >
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() =>
                              openDialog(item.id, item.user_name)
                            }
                          >
                            Approve
                          </Button>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => updateRequest(item.id, "cancel")}
                          >
                            Cancel
                          </Button>
                        </ButtonGroup>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Bottom />
        <BottomNav data={user} />
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={snackBar}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Password Updated
          </Alert>
        </Snackbar>

        {/* dialog for approve confirm */}

        <Dialog open={dialogOpen} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Approve withdraw request of <Chip label={userName} />
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Reference"
              fullWidth
              onChange={(e) => setReference(e.target.value)}
              value={reference}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => updateRequest(id, "approve")}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        {/* viewinfo dialog */}
        <Dialog open={infoDialog} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Bank Details</DialogTitle>
          <DialogContent>
            <TableContainer
              className={classes.tableContainer}
              component={Paper}
            >
              <Table className={classes.table} aria-label="customized table">
                <TableBody>
                  <TableRow>
                    <TableCell align="center" className={classes.tableFont}>
                      Name
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" className={classes.tableFont}>
                      Username
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {userName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" className={classes.tableFont}>
                      Email
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {email}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" className={classes.tableFont}>
                      Bank name
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {bank}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" className={classes.tableFont}>
                      Account number
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {accNo}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" className={classes.tableFont}>
                      IFSC code
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {ifsc}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" className={classes.tableFont}>
                      Upi Id
                    </TableCell>
                    <TableCell align="center" className={classes.tableFont}>
                      {upi}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </div>
  );
};
