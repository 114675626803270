import React from "react";
import AddToHomeScreen from "@ideasio/add-to-homescreen-react";
import "./app.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fff",
    width: "100%",
  },
}));

export default function AddHomeScr() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AddToHomeScreen
        className={classes.home}
        appId="Matka office"
        startAutomatically={true}
        startDelay={0}
        lifespan={10000}
        skipFirstVisit={true}
        displayPace={0}
        customPromptContent={{
          title: "Install Matka Office",
          cancelMsg: "",
          installMsg: "Install",
          guidanceCancelMsg: "Dismiss",
          src: "favicon.ico",
        }}
        customPromptElements={{
          container: "athContainer",
          containerAddOns: "",
          banner: "athBanner",
          logoCell: "athLogoCell",
          logoCellAddOns: "athContentCell",
          logo: "athLogo",
          titleCell: "athTitleCell",
          titleCellAddOns: "athContentCell",
          title: "athTitle",
          cancelButtonCell: "athCancelButtonCell",
          cancelButtonCellAddOns: "athButtonCell",
          cancelButton: "athCancelButton",
          installButtonCell: "athInstallButtonCell",
          installButtonCellAddOns: "athButtonCell",
          installButton: "athInstallButton",
          installButtonAddOns: "button",
          guidance: "athGuidance",
          guidanceImageCell: "athGuidanceImageCell",
          guidanceImageCellAddOns: "",
          guidanceCancelButton: "athGuidanceCancelButton",
        }}
      />
    </div>
  );
}
