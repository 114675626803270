import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  Typography,
  Toolbar,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  Card,
  CardContent,
} from "@material-ui/core";

import MenuBar from "../wrapper/MenuBar";

import BottomNav from "../wrapper/BottomNav";

import axios from "axios";
import Bottom from "../wrapper/Bottom";
import { Alert } from "@material-ui/lab";
import { Redirect } from "react-router-dom";

import UserContext from "../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fafafa",
  },

  title: {
   fontFamily: "Josefin Sans",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#00b7fa",
    fontWeight: "bold",
  },
  subHead: {
    backgroundColor: "#fafafa",
  },
  form: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
  },
  field: {
    // margin: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submit: {
   fontFamily: "Josefin Sans",
    backgroundColor: "#00b7fa",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  card: {
    textAlign: "center",
    flexDirection: "column",
  },

  gameHeader: {
    fontSize: "14",
    fontWeight: "bold",
   fontFamily: "Josefin Sans",
  },
  result: {
   fontFamily: "Josefin Sans",
    fontWeight: "bold",
  },
}));

export const MyReferal = () => {
  const { user } = useContext(UserContext);

  const classes = useStyles();

  //values
  const [code, setCode] = useState("");

  //backdrop
  const [backDrop, setbackDrop] = useState(false);

  //snackbar
  const [snackBar, setSnackBar] = useState(false);

  const [refresh,setRefresh] = useState(false);

  useEffect(async () => {
    const bookieReferal = await axios.get("bookieReferal");

    setCode(bookieReferal.data.referal_code);

    // console.log(bookieReferal);
  }, [refresh]);

  const submitCode = async (e) => {
    setbackDrop(true);
    e.preventDefault();
    const res = await axios.post("bookieReferal", {
      code,
    });

    setRefresh((v)=>!v);

    setbackDrop(false);
    setSnackBar(true);
  };

  //snackbar close function
  const handleClose = () => {
    setSnackBar(false);
  };

  if (user.role === "user") {
    return <Redirect to="/" />;
  }
  if (user.role === "admin") {
    return <Redirect to="/admin" />;
  }
  if(user.role === 'moderator')
  {
    return <Redirect to="/moderator/report"/>
  }
  return (
    <div className={classes.root}>
      <MenuBar />
      <>
        <Toolbar className={classes.subHead}>
          <Typography variant="h6" className={classes.title}>
            My Referal Code
          </Typography>
        </Toolbar>
        <Divider />
        <form
          className={classes.form}
          noValidate
          onSubmit={submitCode}
          autoComplete="off"
        >
 

          <Button
            fullWidth
            variant="contained"
            className={classes.submit}
            color="secondary"
            type="submit"
          >
            Generate Referal Code 
          </Button>
        </form>

        <Divider />
        <Card className={classes.card}>
          <CardContent>
            <Typography
              className={classes.gameHeader}
              color="textSecondary"
              gutterBottom
            >
              My Link
            </Typography>
            <Typography
              className={classes.gameHeader}
              color="textSecondary"
              gutterBottom
            >
              Copy below link and share with your customers
            </Typography>
            <Typography variant="h5" component="h2"></Typography>
            <Typography color="primary" className={classes.result}>
              Https://matkaexpress.com/signup/{code}
            </Typography>
          </CardContent>
        </Card>
        <Divider />
        <Bottom />
        <BottomNav />
        <Backdrop className={classes.backdrop} open={backDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={snackBar}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Refaral code Updated
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};
